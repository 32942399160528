<template>
  <div class="modal" id="modal-term-of-service" aria-hidden="true">
      <div class="modal-dialog" role="document" style="max-width:800px">
          <div class="modal-content">
              <div class="modal-header text-center">
                  <h5 class="modal-title" style="width:100%; color:#212F3F; text-align:center;font-size: 16px">Quy định sử dụng tính năng TopCV Connect</h5>
              </div>
              <div class="modal-body">
                  <div class="list-item">
                      <div class="list-item-number">
                          <div class="list-item-number-wrapper">
                              1
                          </div>
                      </div>
                      <div class="list-item-content">
                          Phiên bản hiện tại là phiên bản thử nghiệm nên trong quá trình sử dụng có thể gặp lỗi hoặc một số tính năng có thể không hoạt động đúng cách khi bạn sử dụng chúng.
                      </div>
                  </div>
                  <div class="list-item">
                      <div class="list-item-number">
                          <div class="list-item-number-wrapper">
                              2
                          </div>
                      </div>
                      <div class="list-item-content">
                          Tài khoản ứng viên được tạo tối đa 5 cuộc hội thoại mới mỗi ngày. Tài khoản nhà tuyển dụng được tạo tối đa 100 cuộc hội thoại mới mỗi ngày.
                      </div>
                  </div>
                  <div class="list-item">
                      <div class="list-item-number">
                          <div class="list-item-number-wrapper">
                              3
                          </div>
                      </div>
                      <div class="list-item-content">
                          Lịch sự khi tương tác, trao đổi. Không gửi hoặc tạo điều kiện cho việc truyền nội dung không phù hợp, số lượng lớn tin nhắn thương mại hay tự động.
                      </div>
                  </div>
                  <div class="list-item">
                      <div class="list-item-number">
                          <div class="list-item-number-wrapper">
                              4
                          </div>
                      </div>
                      <div class="list-item-content">
                          Không dùng tính năng TopCV Connect để thực hiện hành vi lừa đảo (hoặc có dấu hiệu lừa đảo).
                      </div>
                  </div>
                  <div class="list-item">
                      <div class="list-item-number">
                          <div class="list-item-number-wrapper">
                              5
                          </div>
                      </div>
                      <div class="list-item-content">
                          Không yêu cầu hoặc thu thập dữ liệu nhạy cảm. Không dùng tính năng TopCV Connect để lừa những người dùng khác chia sẻ thông tin theo cách sai trái. Không mạo danh người khác. Cung cấp thông tin không đúng về bản thân. Không dùng TopCV Connect để quấy rối, đe dọa hoặc dọa dẫm người khác. Không xúi giục người khác tham gia thực hiện hành vi này.
                      </div>
                  </div>
                  <div class="list-item">
                      <div class="list-item-number">
                          <div class="list-item-number-wrapper">
                              6
                          </div>
                      </div>
                      <div class="list-item-content">
                          Không cho phép lan truyền phần mềm độc hại, vi-rút, Trojan horse, tệp hỏng, mã phá hoại hay bất cứ thứ gì có thể gây hại hay can thiệp vào hoạt động của mạng, máy chủ hoặc cơ sở hạ tầng khác của TopCV hay các tổ chức khác. Không dùng tính năng TopCV Connect để quảng cáo, tổ chức hoặc tham gia các hoạt động bất hợp pháp hoặc tuyên truyền nội dung chống phá nhà nước Việt Nam.
                      </div>
                  </div>
                  <div class="list-item">
                      <div class="list-item-number">
                          <div class="list-item-number-wrapper">
                              7
                          </div>
                      </div>
                      <div class="list-item-content">
                          TopCV có thể vô hiệu hóa những tài khoản bị phát hiện là vi phạm các chính sách này. Nếu tài khoản của bạn bị vô hiệu hóa và bạn cho rằng điều này là nhầm lẫn, vui lòng liên hệ hỗ trợ.
                      </div>
                  </div>
                  <div style="margin-left: 44px;" class="list-item">
                      <div class="list-item-number text-center mt-0 mr-0">
                          <div class="custom-control custom-checkbox">
                              <input type="checkbox" v-model="agreeTerm" class="custom-control-input" id="customCheck1">
                              <label class="custom-control-label" for="customCheck1"></label>
                          </div>
                      </div>
                      <div class="list-item-content position-relative" @click="agreeTerm = !agreeTerm" style="color:#999999; top: 2px">
                          Tôi đồng ý với các quy định và hoàn toàn chịu trách nhiệm nếu vi phạm
                      </div>
                  </div>
                  <div class="text-center mt-5">
                      <button type="button" @click="agreeWithTerm()" :disabled="!agreeTerm" class="btn btn-sm btn-success btn-green">Tôi đồng ý</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import $ from 'jquery';
import authApi from "@/api/authApi";
export default {
  props: ['onSubmit', 'is_agree_term'],
  data() {
    return {
      agreeTerm: false,
    }
  },
  mounted() {
    if (!this.is_agree_term) {
        $("#modal-term-of-service").modal({
            keyboard: false,
            backdrop: 'static',
        });
    }
  },
  methods: {
     async agreeWithTerm() {
          $("#modal-term-of-service").modal('hide');
          let { data } = await authApi.agreeTerm();
          if (data.success) {
              this.$store.dispatch('auth/setProperties', {
                  agree_term: data.agree_term
              });
              setTimeout(() => {
                  this.onSubmit && this.onSubmit();
              });
          }
      },
  }
}
</script>

<style scoped>
.list-item {
    display: flex;
}

.list-item-number {
    min-width: 30px;
    margin-right:16px;
}

.list-item-number-wrapper {
    width: 25px;
    height: 25px;
    background: #E7E9EA;
    text-align: center;
    border-radius: 50%;
    color: #212F3F;
}

.list-item {
    margin-bottom: 10px;
}

.list-item-number {
    margin-top: 5px;
}
</style>