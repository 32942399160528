<template>
  <div>
    <div class="modal fade" aria-labelledby="modal-survey-form" id="modal-survey-form" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 800px;">
            <div class="modal-content">
                <div class="modal-header" style="background: #e5f7ed;">
                    <div class="row w-100">
                        <div class="col-lg-9">
                            <p class="mb-1" style="color: #00b14f; font-weight: 700; font-size: 24px;">Khảo sát tính năng Top Connect</p>
                            <p style="color: #333; font-size: 14px;">
                                Xin cảm ơn anh chị đã sử dụng Top Connect của TopCV. Để tính năng Top Connect tiếp tục được cải tiến theo hướng hỗ trợ ngày một tốt hơn cho công tác tuyển dụng của khách hàng, TopCV nhờ anh chị chia sẻ một số
                                thông tin qua khảo sát dưới đây
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <img style="width: 140px;" src="../assets/survey-form-image.svg" />
                        </div>
                    </div>
                    <button type="button" class="rounded-circle btn-close btn d-flex justify-content-center align-items-center p-0" style="background: rgba(0, 0, 0, 0.05); width: 28px; height: 28px;" data-dismiss="modal" aria-label="Close">
                        <i style="color: #999999;" class="fas fa-times" />
                    </button>
                </div>
                <div class="modal-body">
                    <ol class="list-item m-1 px-4 py-0">
                        <li>
                            <span>Nếu có ứng viên chưa ứng tuyển nhắn tin qua Top Connect để tìm hiểu thêm thông tin về job, anh/chị có sẵn sàng phản hồi ứng viên trong thời gian sớm nhất không? <sub>*</sub></span>
                            <div class="form-check mt-2">
                                <input :value="true" v-model="questions.question1" class="form-check-input" type="radio" name="flexRadioDefault" id="surveyQ1Yes" />
                                <label class="form-check-label" for="surveyQ1Yes">
                                    Có
                                </label>
                            </div>
                            <div class="form-check mt-2">
                                <input :value="false" v-model="questions.question1" class="form-check-input" type="radio" name="flexRadioDefault" id="surveyQ1No" />
                                <label class="form-check-label" for="surveyQ1No">
                                    Không
                                </label>
                            </div>
                        </li>
                        <li class="mt-3">
                            <span>Lý do nào khiến anh/chị sẵn sàng phản hồi/không phản hồi ứng viên?</span>
                            <textarea v-model="questions.question2" rows="4" placeholder="Nhập câu trả lời của bạn..." style="border: 1px solid #eeeeee; font-size: 14px;" class="mt-2 form-control"></textarea>
                        </li>
                        <li class="mt-3">
                            <span>TopCV có thể làm gì để tính năng chat mang lại giá trị tốt hơn cho anh/chị trong tuyển dụng?</span>
                            <textarea v-model="questions.question3" rows="4" placeholder="Nhập câu trả lời của bạn..." style="border: 1px solid #eeeeee; font-size: 14px;" class="mt-2 form-control"></textarea>
                        </li>
                    </ol>
                    <div class="text-center mt-3">
                        <button :disabled="loading" @click="submitSurvey()" type="button" class="px-3 py-2 btn btn-sm btn-success btn-green">
                            <span class="mr-2">Gửi khảo sát</span>
                            <template v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" aria-labelledby="modal-survey-form-success" id="modal-survey-form-success" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 800px;">
            <div class="modal-content">
                <div class="modal-header" style="background: #e5f7ed;">
                    <div class="text-center d-block m-auto p-3">
                      <img src="../assets/survey-form-image-success.svg" />
                    </div>
                    <button type="button" class="rounded-circle btn-close btn d-flex justify-content-center align-items-center p-0" style="background: rgba(0, 0, 0, 0.05); width: 28px; height: 28px;" data-dismiss="modal" aria-label="Close">
                        <i style="color: #999999;" class="fas fa-times" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                      <p style="font-size: 24px; color: #00B14F; font-weight: 700">Xin cảm ơn</p>
                      <p style="padding: 0px 100px">TopCV xin cảm ơn chia sẻ của anh/chị. Chúc anh/chị một ngày tốt lành!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import authApi from "@/api/authApi";
export default {
  props: ['onSubmit', 'is_submitted_survey_form', 'is_agree_term', 'user'],
  data() {
    return {
      questions: {
        question1: null,
        question2: '',
        question3: '',
      },
      loading: false
    }
  },
  watch: {
    is_agree_term: {
      deep: true,
      handler() {
        const surveyFormDate = localStorage.getItem('survey_form_date');
        if (!this.is_submitted_survey_form && this.is_agree_term && surveyFormDate !== this.getDate()) {
          setTimeout(() => {
            $("#modal-survey-form").modal();
            this.logSurveyData();
          });
        }
      },
      immediate: true
    }
  },
  mounted() {
    $('#modal-survey-form').on('hide.bs.modal', () => {
        localStorage.setItem('survey_form_date', this.getDate());
    });
  },
  methods: {
    getDate() {
      const date = new Date();
      return date.getDate() + '_' + date.getMonth() + '_' + date.getFullYear() + '_' + this.user.id;
    },
    async logSurveyData() {
      await authApi.logSurveyData();
    },
    async submitSurvey() {
       if (typeof this.questions.question1 !== 'boolean') {
         return alert('Không được để trống câu hỏi 1');
       }
       this.loading = true;
       const { data } = await authApi.submitSurveySurvey(this.questions);
       this.loading = false;
       if (!data.success) {
         $("#modal-survey-form").modal();
         return alert(data.message);
       }
       $("#modal-survey-form").modal('hide');
       $('#modal-survey-form-success').modal();
     },
  }
}
</script>

<style scoped>
.list-item {
  font-size: 15px;
}
.list-item span {
  font-weight: 700;
}
.list-item sub {
  color: red;
  position: relative;
  top: -2px;
}
.form-check-input {
  accent-color: #28a745;
}
</style>