<template>
  <div class="position-relative" style="height: 100vh">
    <div class="row m-0" style="overflow: hidden; height: 100vh">
      <div v-if="!embedMode && (!isMobile || (isMobile && !hasChatQuery && currentMobileTab === 'Conversations'))"
           :class="isMobile && !hasChatQuery ? 'col-12' : 'col-3'" class="h-100 border-right h-100vh p-0">
        <div class="d-flex justify-content-between px-4 mt-4">
          <img alt="" style="height: 100%" width="190" src="../assets/logo_beta.png">
          <div class="d-flex justify-content-between">
            <div class="mr-2" id="home-back"><a href="https://tuyendung.topcv.vn"
                                                style="border-radius: 25px; padding: 8px 15px;white-space: nowrap;"
                                                class="no-decoration text-ssm icon-shape">Về trang chủ</a></div>
            <div class="dropdown position-relative" style="top: -3px;cursor: pointer;" v-tooltip="'Cài đặt'">
              <i class="fas icon-shape fa-cog" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false"></i>
              <ul class="rounded dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                <li>
                  <div class="dropdown-item p-1">
                    <div class="d-flex justify-content-between">
                      <div class="mr-4" style="font-size: 14px">
                        <label class="mr-4" style="font-size: 14px" for="notification-switch">
                          <i class="fas fa-bell icon-shape mr-1"></i>
                          <span style="color: #555">Nhận thông báo</span>
                        </label>
                      </div>
                      <div class="mt-1">
                        <div class="custom-control custom-switch">
                          <input v-model="user.notification_status"
                                 @click="toggleNotificationOrSoundStatus({notification_status: $event.target.checked})"
                                 type="checkbox" class="custom-control-input" id="notification-switch">
                          <label class="custom-control-label" for="notification-switch"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown-item p-1">
                    <div class="d-flex justify-content-between">
                      <div class="mr-4" style="font-size: 14px">
                        <label class="mr-4" style="font-size: 14px" for="sound-switch">
                          <i class="fas icon-shape fa-volume-up mr-1"></i>
                          <span style="color: #555">Âm thanh thông báo</span>
                        </label>
                      </div>
                      <div class="mt-1">
                        <div class="custom-control custom-switch">
                          <input v-model="user.sound_status"
                                 @click="toggleNotificationOrSoundStatus({sound_status: $event.target.checked})"
                                 type="checkbox" class="custom-control-input" id="sound-switch">
                          <label class="custom-control-label" for="sound-switch"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li style="poiter: cursor" @click="logout()">
                  <div class="dropdown-item p-1">
                    <div class="d-flex justify-content-between">
                      <div class="mr-4" style="font-size: 14px">
                        <label class="mr-4" style="font-size: 14px">
                          <!-- <i class="fas icon-shape fa-volume-up mr-1"></i> -->
                          <i class="fas icon-shape fa-sign-out-alt"></i>
                          <span style="color: #555">Đăng xuất</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row px-3" style="height: auto !important">
          <div v-if="isFocusedSearchInput" class="col-1 mt-4 px-1">
            <i style="height: 45px; width: 45px; text-align: center; padding: 13px"
               class="text-muted fas fa-arrow-left"></i>
          </div>
          <div :class="isFocusedSearchInput ? 'col-11': 'col-12'" class="search-form position-relative px-3 mt-4">
            <i class="search-icon position-absolute fas fa-search"></i>
            <input @input="debounceSearch" @focus="isFocusedSearchInput = true, conversationsSearched = []"
                   @blur="onBlurOfSearchConversation" placeholder="Họ tên ứng viên..." id="search-input"
                   class="no-outline w-100 search-input" type="text">
          </div>
        </div>
        <template v-if="isFocusedSearchInput">
          <div class="list-conversations mt-2 h-80vh">
            <template v-if="isSearchingConversation || !user.agree_term">
              <content-loader
                  v-for="n in 20"
                  :key="n"
                  :width="460"
                  :height="60"
                  :speed="2"
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                  class="mt-3 mx-4"
              >
                <rect x="68" y="6" rx="10" ry="10" width="300" height="20"/>
                <rect x="69" y="37" rx="10" ry="10" width="180" height="17"/>
                <circle cx="28" cy="31" r="28"/>
              </content-loader>
            </template>
            <template v-else>
              <template v-if="conversationsSearched.length > 0">
                <div v-for="(conversation, index) in conversationsSearched" :key="index"
                     class="conversation-item d-flex justify-content-between mt-2 px-4"
                     @click="fetchConversation(conversation, true)"
                     :class="{'active-chat': conversation.conversation._id == currentConversation.conversation._id}">
                  <div class="media">
                    <avatar class="conversations-avatar rounded-circle"
                            :src="(conversation.is_uploaded_avatar && conversation.avatar) ? conversation.avatar : null"
                            :username="conversation.fullname ? conversation.fullname : ''"></avatar>
                    <div class="mt-1 ml-2 media-body">
                      <h5 class="mb-0 conversations-username">{{ conversation.fullname }}</h5>
                      <p class="mt-2 conversations-demo-message text text-muted text-sm">
                        <span :class="{'font-weight-bold text-theme-2': !isSeenLastMessage(conversation.conversation)}"
                              v-html="renderMessage(conversation.conversation.last_message.length > 15 ? conversation.conversation.last_message.substr(0, 15) + '...' : conversation.conversation.last_message)"></span>
                        <span aria-hidden="true" class="text-ssm text-muted"> · {{
                            timeAgo(conversation.conversation.last_update)
                          }}</span>
                      </p>
                    </div>
                  </div>
                  <div v-if="!isSeenLastMessage(conversation.conversation)" class="not-read-dot"></div>
                </div>
              </template>
              <template v-else>
                <p class="mt-3 text-center text-muted text-ssm">Không tìm thấy cuộc trò chuyện nào</p>
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <div @scroll="onScrollConversation" id="list-conversations" class="list-conversations mt-2 h-80vh">
            <template v-if="isFetchingConversation || !user.agree_term">
              <content-loader
                  v-for="n in 20"
                  :key="n"
                  :width="460"
                  :height="60"
                  :speed="2"
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                  class="mt-3 mx-4"
              >
                <rect x="68" y="6" rx="10" ry="10" width="300" height="20"/>
                <rect x="69" y="37" rx="10" ry="10" width="180" height="17"/>
                <circle cx="28" cy="31" r="28"/>
              </content-loader>
            </template>
            <!-- Start Conversation Item -->
            <template v-else>
              <!-- <div class="p-2 mx-1 mt-0 mb-2">
                  <a target="_blank" href="https://www.topcv.vn/app-download">
                      <img src="../assets/banner.jpg" class="rounded w-100" alt="">
                  </a>
              </div> -->
              <div class="comments-box p-3 mx-4 mt-2 mb-3">
                <strong>Bạn đánh giá trải nghiệm của mình với TopCV Connect thế nào?</strong>
                Chia sẻ ngay với đội ngũ phát triển để chúng tôi cải thiện trải nghiệm của tính năng cho phù hợp
                <a href="https://forms.gle/B7JaoKDr6ufcZsa98" target="_blank" class="mt-3 btn w-100"
                   style="background: #EBF3FE; font-size: 13px; color: #4285F4">Gửi phản hồi</a>
              </div>
              <div>
                <button class="btn btn-light ml-3" :class="{'btn-active-tab': activeTab === 'all'}"
                        @click="handleChangeTab('all')">
                  Tất cả
                </button>
                <button class="btn btn-light" :class="{'btn-active-tab': activeTab === 'unread'}"
                        @click="handleChangeTab('unread')">
                  Chưa đọc
                    <div v-if="unreadConversations.length > 0" class="not-read-dot-button"></div>
                </button>
              </div>
                <template v-if="isLoadingNewChat">
                    <content-loader
                            :width="460"
                            :height="60"
                            :speed="2"
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            class="mt-3 mx-4"
                    >
                        <rect x="68" y="6" rx="10" ry="10" width="300" height="20"/>
                        <rect x="69" y="37" rx="10" ry="10" width="180" height="17"/>
                        <circle cx="28" cy="31" r="28"/>
                    </content-loader>
                </template>
                <template v-else-if="showNewChat">
                    <div class="conversation-item d-flex justify-content-between mt-2 px-4 active-chat">
                        <div class="media">
                            <avatar class="conversations-avatar rounded-circle"
                                    :src="(newChat.is_uploaded_avatar && newChat.avatar) ? newChat.avatar : null"
                                    :username="newChat.fullname ? newChat.fullname : ''"></avatar>
                            <div class="mt-1 ml-2 media-body">
                                <h5 class="mb-0 conversations-username">{{ newChat.fullname }}</h5>
                                <p class="mt-2 conversations-demo-message text text-muted text-sm">
                                    <span>Cuộc trò chuyện mới</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
              <div v-for="(conversation, index) in showedConversations" :key="index"
                   class="conversation-item d-flex justify-content-between mt-2 px-4"
                   @click="fetchConversation(conversation, true)"
                   :class="{'active-chat': conversation.conversation._id == currentConversation.conversation._id}">
                <div class="media">
                  <div class="position-relative">
                    <avatar class="conversations-avatar rounded-circle"
                            :src="(conversation.is_uploaded_avatar && conversation.avatar) ? conversation.avatar : null"
                            :username="conversation.fullname ? conversation.fullname : ''"></avatar>
                    <div class="position-absolute" :class="conversation.online ? 'online-dot' : 'offline-dot'"></div>
                  </div>
                  <div class="mt-1 ml-2 media-body">
                    <h5 class="mb-0 conversations-username">{{ conversation.fullname }}</h5>
                    <p class="mt-2 conversations-demo-message text text-muted text-sm">
                      <span :class="{'font-weight-bold text-theme-2': !isSeenLastMessage(conversation.conversation)}"
                            v-html="renderMessage(conversation.conversation.last_message.length > 15 ? conversation.conversation.last_message.substr(0, 15) + '...' : conversation.conversation.last_message)"></span>
                      <span aria-hidden="true" class="text-ssm text-muted"> · {{
                          timeAgo(conversation.conversation.last_update)
                        }}</span>
                    </p>
                  </div>
                </div>
                <div v-if="!isSeenLastMessage(conversation.conversation)" class="not-read-dot"></div>
              </div>
            </template>
            <div v-if="isLoadingMoreConversation" class="text text-center mb-5">
              <loading class="loading-more-message"/>
            </div>
            <!-- End Conversation Item -->
          </div>
        </template>
      </div>
      <div v-if="!isMobile || (hasChatQuery && isMobile && currentMobileTab != 'ConversationInfo')" class="p-0 h-100"
           :class="embedMode || (isMobile && hasChatQuery) ? 'col-12' : 'col-6'">
        <div id="slogan" class="slogan border-bottom p-3 m-0">
          <h5 style="font-size: 15px; font-weight: bold" class="m-0">New way to follow your your chance. <span
              class="text-theme-2">More engage, more success</span></h5>
        </div>
        <div v-if="showNewChat" id="chat-header" class="p-3 header-conversation border-bottom">
          <div class="d-flex justify-content-between">
            <div class="media">
              <i @click="mobileBack()" v-if="isMobile && hasChatQuery"
                 style="height: 45px; width: 45px; text-align: center; padding: 13px"
                 class="mt-1 mr-2 text-muted fas fa-arrow-left"></i>
              <div class="position-relative">
                <avatar class="conversations-avatar rounded-circle"
                        :src="(newChat.is_uploaded_avatar && newChat.avatar) ? newChat.avatar : null"
                        :username="newChat.fullname ? newChat.fullname : ''" alt="..."></avatar>
                <div class="position-absolute" :class="newChat.online ? 'online-dot' : 'offline-dot'"></div>
              </div>
              <div class="mt-2 ml-2 media-body">
                <h5 class="mb-0 conversations-username">{{ newChat.fullname }}</h5>
                <p v-if="newChat.last_online" class="mt-1 conversations-demo-message text text-muted text-sm">
                  {{ newChat.online ? 'Đang hoạt động' : `Hoạt động ${timeAgo(newChat.last_online)}` }}</p>
                <p v-else class="mt-1 conversations-demo-message text text-muted text-sm">{{
                    newChat.email || 'Người dùng TopCV'
                  }}</p>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <!-- <div data-toggle="tooltip" title="Tải lại cuộc trò chuyện" @click="fetchConversation(currentConversation.user, false)" class="mr-2"><i class="icon-shape fas fa-redo-alt"></i></div> -->
              <!-- <div data-toggle="tooltip" title="Cài đặt" class="mr-2"><i class="icon-shape fas fa-cog"></i></div>
              <div data-toggle="tooltip" title="Cuộc trò chuyện video" class="mr-2"><i class="icon-shape fas fa-video"></i></div>
              <div data-toggle="tooltip" title="Thông tin cuộc trò chuyện" class=""><i class="icon-shape fas fa-info-circle"></i></div> -->
            </div>
          </div>
        </div>
        <template
            v-if="currentConversation.conversation._id && (showChat || (!isFetchingConversation && conversations.length > 0))">
          <div v-if="currentConversation.conversation._id && !isFetchingMessage && user.agree_term" id="chat-header"
               class="p-3 header-conversation border-bottom">
            <div class="d-flex justify-content-between">
              <div class="media">
                <i @click="mobileBack()" v-if="isMobile && hasChatQuery"
                   style="height: 45px; width: 45px; text-align: center; padding: 13px"
                   class="mt-1 mr-2 text-muted fas fa-arrow-left"></i>
                <div class="position-relative">
                  <avatar class="conversations-avatar rounded-circle"
                          :src="(currentConversation.user.is_uploaded_avatar && currentConversation.user.avatar) ? currentConversation.user.avatar : null"
                          :username="currentConversation.user.fullname ? currentConversation.user.fullname : 'Người dùng TopCV'"
                          alt="..."></avatar>
                  <div class="position-absolute"
                       :class="currentConversation.user.online ? 'online-dot' : 'offline-dot'"></div>
                </div>
                <div class="mt-2 ml-2 media-body">
                  <h5 class="mb-0 conversations-username">{{ currentConversation.user.fullname }}</h5>
                  <p v-if="currentConversation.user.last_online"
                     class="mt-1 conversations-demo-message text text-muted text-sm">{{
                      currentConversation.user.online ?
                          'Đang hoạt động' :
                          `Hoạt động ${timeAgo(currentConversation.user.last_online)}`
                    }}</p>
                  <p v-else class="mt-1 conversations-demo-message text text-muted text-sm">
                    {{ currentConversation.user.email || 'Người dùng TopCV' }}</p>
                  <p v-if="currentConversation.user.total_reply_times == 0"
                     class="mt-1 conversations-demo-message text text-muted text-sm">
                    Thường trả lời ngay lập tức
                  </p>
                  <template v-else>
                    <p class="mt-1 conversations-demo-message text text-muted text-sm">
                      Thường trả lời
                      <span v-if="avgTimeAnswer < 60" class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng một phút
                                            </span>
                      <span v-else-if="avgTimeAnswer < 60 * 2"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng 2 phút
                                            </span>
                      <span v-else-if="avgTimeAnswer < 60 * 5"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng 5 phút
                                            </span>
                      <span v-else-if="avgTimeAnswer < 60 * 10"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng 10 phút
                                            </span>
                      <span v-else-if="avgTimeAnswer < 60 * 30"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng 30 phút
                                            </span>
                      <span v-else-if="avgTimeAnswer < 60 * 45"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng 45 phút
                                            </span>
                      <span v-else-if="avgTimeAnswer < 3600"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng một tiếng
                                            </span>
                      <span v-else-if="avgTimeAnswer < 3600 * 5"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng vài tiếng
                                            </span>
                      <span v-else-if="avgTimeAnswer < 3600 * 24"
                            class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong ngày
                                            </span>
                      <span v-else class="mt-1 conversations-demo-message text text-muted text-sm">
                                                trong khoảng vài ngày
                                            </span>
                    </p>

                  </template>

                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2">
                                <!-- <div data-toggle="tooltip" title="Mở cuộc trò chuyện trong tab mới" v-if="embedMode" class="mr-2"><a target="_blank" :href="`${$router.currentRoute.path}?chat=${$router.currentRoute.query.chat}`"><i class="icon-shape fas fa-expand"></i></a></div> -->
                                <!-- <div data-toggle="tooltip" title="Tải lại cuộc trò chuyện" @click="fetchConversation(currentConversation.user, false)" class="mr-2"><i class="icon-shape fas fa-redo-alt"></i></div> -->
                                <!-- <div data-toggle="tooltip" title="Cài đặt" class="mr-2"><i class="icon-shape fas fa-cog"></i></div>
                                <div data-toggle="tooltip" title="Cuộc trò chuyện video" class="mr-2"><i class="icon-shape fas fa-video"></i></div>
                                <div data-toggle="tooltip" title="Thông tin cuộc trò chuyện" class=""><i class="icon-shape fas fa-info-circle"></i></div> -->
                              <div class="dropdown position-relative">
                                <i style="width: 32px;height: 32px;text-align: center;" class="fas icon-shape fa-ellipsis-v cursor-pointer" id="dropdownCandidateInfoMenu" data-toggle="dropdown" aria-haspopup="true"
                                   aria-expanded="false"></i>
                                <ul class="rounded dropdown-menu p-2" aria-labelledby="dropdownCandidateInfoMenu">
                                  <li @click="openConversationInfo()" v-if="isMobile">
                                    <div class="dropdown-item p-1">
                                      <div class="d-flex justify-content-between">
                                        <div class="mr-4" style="font-size: 14px">
                                          <label class="mr-4  mb-0 cursor-pointer" style="font-size: 14px" for="candidate-detail">
                                            <i class="fas fa-user mr-1"></i>
                                            <span style="color: #555">Xem thông tin ứng viên</span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                <li @click="openBlockCandidateModal()">
                                  <div class="dropdown-item p-1">
                                    <div class="d-flex justify-content-between">
                                      <div class="mr-4" style="font-size: 14px">
                                        <label class="mr-4 mb-0 cursor-pointer" style="font-size: 14px" for="candidate-block">
                                          <i class="fas fa-ban mr-1" style="color: red"></i>
                                          <span style="color: #555">Chặn ứng viên</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                </ul>
                              </div>

                            </div>
                        </div>
                    </div>
                    <div id="chat-header" class="p-3 header-conversation border-bottom" v-else>
                        <content-loader
                            :width="600"
                            :height="53"
                            :speed="2"
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                        >
                            <circle cx="27" cy="27" r="18" />
                            <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                            <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                            <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                            <circle cx="540" cy="27" r="8" />
                            <circle cx="560" cy="27" r="8" />
                            <circle cx="580" cy="27" r="8" />
                            <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                            <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                        </content-loader>
                    </div>
                    <div class="w-100 position-relative" style="height: 100vh" v-if="isFetchingMessage">
                        <loading-circle text="Đang tải cuộc trò chuyện..."/>
                    </div>
                    <div style="cursor: auto;" v-else @click="showEmoji = false" id="chat-body" @scroll="onScrollChat" class="position-relative chat-body p-3">
                        <div v-if="user.agree_term" class="chat-conversation">
                            <div v-if="isLoadingMoreMessage" class="text text-center mb-5">
                                <loading class="loading-more-message" />
                            </div>
                            <div v-else class="text text-center mb-5">
                                <a @click="loadingMoreMessage" v-if="!isFetchedAllMessages && currentConversation.messages.length >= 20" class="text-muted text-sm text-underline">Xem thêm</a>
                                <div v-if="currentConversation.messages.length == 0" class="mt-5 text-muted text-sm">
                                    <img style="width: 200px;" class="d-block m-auto" src="../assets/say-hello.png" />
                                    <p class="text-sm text-center mt-4">Hãy bắt đầu cuộc trò chuyện bằng một lời chào 😍</p>
                                </div>
                            </div>
                            <div v-for="(groupMessage, index) in groupMessages" :key="index" class="chat">
                                <p class="text-center text-muted text-sm mt-3" v-if="showChatTimeWhenFarGroup(index)"><small>{{ dateFormat(groupMessage[0].created_at) }}</small></p>
                                <div v-if="groupMessage[0] && groupMessage[0].employer_send_id" class="ml-2 position-relative mb-0">
                                    <div class="chat-right" v-for="(message, i) in groupMessage" :key="i">
                                        <div v-if="message.message_type === 2" v-tooltip="dateFormat(message.created_at)" class="message d-flex flex-column" :class="{'first-message': i == 0, 'last-message': i == groupMessage.length - 1, 'border-message': groupMessage.length == 1}">
                                          <span style="font-weight: 600">Bạn đã gửi yêu cầu tìm hiểu nhu cầu công việc đến ứng viên</span>
                                          <span class="message-2" v-html="renderMessage(message.message_body)"></span>
                                        </div>
                                        <div v-else class="message" v-tooltip="dateFormat(message.created_at)" :class="{'first-message': i == 0, 'last-message': i == groupMessage.length - 1, 'border-message': groupMessage.length == 1}" v-html="renderMessage(message.message_body)"></div>
                                        <span class="ml-2 message-status-icon position-relative">
                                            <span v-if="message.is_last_seen" title="Đã xem" v-tooltip="'Đã xem'">
                                                <i>
                                                    <avatar :size="14"
                                                            style="border-radius: 50%; width: 14px; height: 14px; object-fit: cover; background-size: contain !important; background-position: center !important;"
                                                            :src="(currentConversation.user.is_uploaded_avatar && currentConversation.user.avatar) ? currentConversation.user.avatar : null"
                                                            :username="currentConversation.user.fullname ? currentConversation.user.fullname : ''"></avatar>
                                                </i>
                                            </span>
                                            <span v-else>
                                                <span v-tooltip="'Đang gửi...'" v-if="message.is_sending"><i
                                                    class="far fa-check-circle"></i></span>
                                                <span v-tooltip="'Đã gửi'" v-if="message.sent_success"><i
                                                    class="fas fa-check-circle"></i></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div v-else class="media position-relative">
                                    <div class="position-absolute" v-if="groupMessage[0].message_type !== 4" style="top: 20px">
                                        <avatar v-tooltip="currentConversation.user.fullname ? currentConversation.user.fullname : ''" :size="32" class="chat-avatar rounded-circle" :src="(currentConversation.user.is_uploaded_avatar && currentConversation.user.avatar) ? currentConversation.user.avatar : null" :username="currentConversation.user.fullname ? currentConversation.user.fullname : ''"></avatar>
                                    </div>
                                    <div style="margin-left: 34px; margin-top: 20px" class="chat-left">
                                        <div class="chat-left" v-for="(message, i) in groupMessage" :key="i">
                                            <div
                                                v-if="message.message_type === 3"
                                                class="message ml-2 d-flex flex-column"
                                                v-tooltip="dateFormat(message.created_at)"
                                                :class="{'first-message': i == 0, 'last-message': i == groupMessage.length - 1, 'border-message': groupMessage.length == 1}"
                                            >
                                              <div style="font-weight: 600">Ứng viên đã chia sẻ thông tin nhu cầu công việc cho bạn</div>
                                              <div class="message-3 position-relative">
                                                <div
                                                    v-html="renderMessage(message.message_body)"
                                                    style="overflow-y: hidden;"
                                                    :style="expandMessage[message._id] ? 'height: auto' : 'height: 100px'"
                                                />
                                                <div
                                                    class="position-absolute w-100"
                                                    style="bottom: 0;height: 48px; border-bottom-right-radius: 8px; border-bottom-left-radius: 8px; margin-left: -8px"
                                                    :style="expandMessage[message._id] ? '' : 'background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);'"
                                                />
                                              </div>
                                              <div class="action-message" :style="isLongMessage(message.message_body) ? 'margin-top: -6px' : 'margin-top: 6px'" >
                                                <div
                                                    v-if="isLongMessage(message.message_body)"
                                                    class="d-flex align-items-center justify-content-center"
                                                    style="height: 32px; margin-bottom: 8px; background: #fff; cursor: pointer; left: 0; border-radius: 5px;"
                                                    @click="handleExpandMessage(message._id)"
                                                >
                                                  {{ expandMessage[message._id] ? 'Thu gọn' : 'Xem thêm'}}
                                                </div>
                                                <div
                                                    @click="goViewCV(message.user_send_id)"
                                                    class="d-flex align-items-center justify-content-center"
                                                    style="height: 32px; margin-bottom: 8px; background: #fff; cursor: pointer; color: #00b14f; font-weight: 600;box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);border-radius: 5px;">
                                                  Xem CV
                                                </div>
                                              </div>
                                            </div>
                                            <div class="text-center"
                                                 v-else-if="message.message_type === 4"
                                                 style="font-size: 12px; color: #5e6368; position: absolute; width: 100%; left: 0; top: -5px"
                                            >
                                              Ứng viên đã từ chối chia sẻ thông tin nhu cầu công việc cho bạn.
                                              <span style="cursor: pointer; color: #00b14f;" @click="goViewCV(message.user_send_id)">Xem CV</span>
                                            </div>
                                            <div v-else class="message ml-2 message" v-tooltip="dateFormat(message.created_at)" :class="{'first-message': i == 0, 'last-message': i == groupMessage.length - 1, 'border-message': groupMessage.length == 1}" v-html="renderMessage(message.message_body)"></div>
                                            <span class="ml-2 message-status-icon position-absolute" style="right: 3px; bottom: -5px">
                                                <span v-if="message.is_last_seen" title="Đã xem" v-tooltip="'Đã xem'">
                                                    <i>
                                                        <avatar :size="14"
                                                                style="border-radius: 50%; width: 14px; height: 14px; object-fit: cover; background-size: contain !important; background-position: center !important;"
                                                                :src="(currentConversation.user.is_uploaded_avatar && currentConversation.user.avatar) ? currentConversation.user.avatar : null"
                                                                :username="currentConversation.user.fullname ? currentConversation.user.fullname : ''"></avatar>
                                                    </i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="showNewChat && user.agree_term">
                    <div id="chat-body" class="pt-5 text-center d-block m-auto">
                        <div class="mb-2">
                            <avatar class="d-block text-center m-auto conversations-avatar rounded-circle" :src="(newChat.is_uploaded_avatar && newChat.avatar) ? newChat.avatar : null" :username="newChat.fullname ? newChat.fullname : ''"></avatar>
                        </div>
                        <p class="text-sm font-weight-bold text-dark mt-2 mb-0">{{ newChat.fullname }}</p>
                        <small class="text-muted text-ssm">{{ newChat.email }}</small>
                        <div>
                            <p class="text-sm text-center mt-3">Hãy bắt đầu cuộc trò chuyện bằng một lời chào 😍</p>
                        </div>
                        <div class="w-100 position-relative" style="height: 100vh" v-if="isFetchingNewChat">
                            <loading-circle text="Đang tạo cuộc trò chuyện..."/>
                        </div>
                    </div>
                </template>
                <template v-if="showNewChat || (currentConversation.conversation._id && (showChat || (!isFetchingConversation && conversations.length > 0)))">
                  <div v-if="currentCandidate">
                    <VEmojiPicker
                        v-show="showEmoji"
                        labelSearch="Tìm kiếm"
                        lang="pt-BR"
                        @select="onSelectEmoji"
                    />
                    <div v-if="!isFetchingMessage && user.agree_term && !currentConversation.user.blocked" id="chat-footer" class="chat-input px-3">
                        <div class="text-theme d-flex bd-highlight chat-input-item">
                            <div class="bd-highlight pr-2">
                                <!-- <svg class="_7oal" height="36px" width="36px" viewBox="0 0 36 36"><g fill="none" fill-rule="evenodd"><polygon points="0 36 36 36 36 0 0 0"></polygon><path d="M27.002,13.5 L22.502,13.5 C21.95,13.5 21.502,13.948 21.502,14.5 L21.502,21.5 C21.502,22.052 21.95,22.5 22.502,22.5 C23.054,22.5 23.502,22.052 23.502,21.5 L23.502,19.5 L26.502,19.5 C27.054,19.5 27.502,19.052 27.502,18.5 C27.502,17.948 27.054,17.5 26.502,17.5 L23.502,17.5 L23.502,15.5 L27.002,15.5 C27.554,15.5 28.002,15.052 28.002,14.5 C28.002,13.948 27.554,13.5 27.002,13.5 Z M19.502,14.5 C19.502,13.948 19.054,13.5 18.502,13.5 C17.95,13.5 17.502,13.948 17.502,14.5 L17.502,21.5 C17.502,22.052 17.95,22.5 18.502,22.5 C19.054,22.5 19.502,22.052 19.502,21.5 L19.502,14.5 Z M14.963,16.9995 L12.502,17 C11.95,17 11.502,17.448 11.502,18 C11.502,18.552 11.95,19 12.502,19 L13.8855,19 C13.7835,20.1495 13.095,21 11.829,21 C10.3875,21 9.4925,19.725 9.4925,17.95 C9.4925,16.207 10.4655,14.9785 11.886,14.9785 C12.6395,14.9785 13.109,15.3665 13.4295,15.764 C13.6765,16.0695 14,16.229 14.353,16.229 C15.0175,16.229 15.574,15.5085 15.094,14.791 C14.471,13.859 13.335,13.25 11.8795,13.25 C9.179,13.25 7.502,15.2135 7.502,17.9735 C7.502,20.7655 9.071,22.75 11.798,22.75 C14.352,22.75 16.002,20.982 16.002,17.986 C16.002,17.441 15.5365,16.9995 14.963,16.9995 Z M27,30 L17,30 C15.811,30 14.7455,29.48 14.0135,28.656 C13.6395,28.235 13.1,28 12.537,28 L9,28 C6.791,28 5,26.209 5,24 L5,10 C5,7.791 6.791,6 9,6 L19,6 C20.189,6 21.2545,6.52 21.9865,7.3435 C22.3605,7.7645 22.9,8 23.463,8 L27,8 C29.209,8 31,9.791 31,12 L31,26 C31,28.209 29.209,30 27,30 Z" fill="#212f3f"></path></g></svg>
                                <svg class="_7oal" height="36px" width="36px" viewBox="0 0 36 36"><g fill="none" fill-rule="evenodd"><g><polygon points="0 36 36 36 36 0 0 0"></polygon><path d="M13.5,11 C12.1195,11 11,12.119 11,13.5 C11,14.881 12.1195,16 13.5,16 C14.8805,16 16,14.881 16,13.5 C16,12.119 14.8805,11 13.5,11 M26.638,21.467 L21.2375,18.767 C19.199,17.7485 16.801,17.7485 14.7625,18.767 L9.362,21.467 C9.1955,21.55 9,21.429 9,21.243 L9,11 C9,9.8955 9.8955,9 11,9 L25,9 C26.1045,9 27,9.8955 27,11 L27,21.243 C27,21.429 26.8045,21.55 26.638,21.467 M25,7 L11,7 C8.7905,7 7,8.791 7,11 L7,25 C7,27.209 8.7905,29 11,29 L25,29 C27.209,29 29,27.209 29,25 L29,11 C29,8.791 27.209,7 25,7" fill="#212f3f"></path></g></g></svg> -->
                                <svg @click="showEmoji = !showEmoji" id="emoji-icon" class="emoji-icon mx-1 a8c37x1j ms05siws hr662l2t b7h9ocf4" height="24px" width="24px" viewBox="0 0 38 38"><g fill="#212f3f" fill-rule="evenodd"><g transform="translate(-893.000000, -701.000000)"><g transform="translate(709.000000, 314.000000)"><g><path d="M210.5,405 C209.121,405 208,403.879 208,402.5 C208,401.121 209.121,400 210.5,400 C211.879,400 213,401.121 213,402.5 C213,403.879 211.879,405 210.5,405 M212.572,411.549 C210.428,413.742 206.938,415 203,415 C199.062,415 195.572,413.742 193.428,411.549 C192.849,410.956 192.859,410.007 193.451,409.428 C194.045,408.85 194.993,408.859 195.572,409.451 C197.133,411.047 199.909,412 203,412 C206.091,412 208.867,411.047 210.428,409.451 C211.007,408.859 211.956,408.85 212.549,409.428 C213.141,410.007 213.151,410.956 212.572,411.549 M195.5,400 C196.879,400 198,401.121 198,402.5 C198,403.879 196.879,405 195.5,405 C194.121,405 193,403.879 193,402.5 C193,401.121 194.121,400 195.5,400 M203,387 C192.523,387 184,395.523 184,406 C184,416.477 192.523,425 203,425 C213.477,425 222,416.477 222,406 C222,395.523 213.477,387 203,387" class="sqpo3gyd"></path></g></g></g></g></svg>
                            </div>
                            <div class="flex-grow-1 bd-highlight">
                                <div style="display: flex; flex-wrap: wrap" class="h-100">
                                    <div style="display: flex; flex-wrap: wrap; flex: 1 0; position: relative" class="h-100">
                                        <div style="flex: 1; height: auto" class="h-100 position-relative">
                                            <textarea v-model="text" id="chat-box-input" placeholder="Nhập tin nhắn" class="position-absolute no-outline w-100 chat-input-box" type="text"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="cursor: pointer;" @click="startConversationOrSendMessage()" class="bd-highlight pl-2">
                                <svg height="36px" width="36px" viewBox="0 0 36 36"><g fill="none" fill-rule="evenodd"><g><polygon points="0 36 36 36 36 0 0 0"></polygon><path d="M31.1059281,19.4468693 L10.3449666,29.8224462 C8.94594087,30.5217547 7.49043432,29.0215929 8.17420251,27.6529892 C8.17420251,27.6529892 10.7473302,22.456697 11.4550902,21.0955966 C12.1628503,19.7344961 12.9730756,19.4988922 20.4970248,18.5264632 C20.7754304,18.4904474 21.0033531,18.2803547 21.0033531,17.9997309 C21.0033531,17.7196073 20.7754304,17.5095146 20.4970248,17.4734988 C12.9730756,16.5010698 12.1628503,16.2654659 11.4550902,14.9043654 C10.7473302,13.5437652 8.17420251,8.34697281 8.17420251,8.34697281 C7.49043432,6.9788693 8.94594087,5.47820732 10.3449666,6.1775158 L31.1059281,16.553593 C32.298024,17.1488555 32.298024,18.8511065 31.1059281,19.4468693" fill="#212f3f"></path></g></g></svg>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentConversation.user.blocked" class="chat-input block-chat-input">
                      <div class="d-flex block-chat-wrap p-3">
                        <div class="block-chat-image">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="20" fill="#FFF3F2"/>
                            <path d="M20 12C24.4062 12 28 15.5938 28 20C28 24.4375 24.4062 28 20 28C15.5625 28 12 24.4375 12 20C12 15.5938 15.5625 12 20 12ZM14 20C14 23.3125 16.6875 26 20 26C21.2812 26 22.4688 25.5938 23.4688 24.9062L15.0938 16.5312C14.4062 17.5312 14 18.7188 14 20ZM24.875 23.5C25.5625 22.5 26 21.3125 26 20C26 16.7188 23.2812 14 20 14C18.6875 14 17.5 14.4375 16.5 15.125L24.875 23.5Z" fill="#DA4538"/>
                          </svg>

                        </div>
                        <div class="flex-grow-1 block-chat-message">
                          <p class="block-msg">Bạn đang chặn tin nhắn từ ứng viên này</p>
                          <span  class="block-msg">Ứng viên sẽ không nhắn tin được cho bạn</span>
                        </div>
                        <div class="block-chat-button">
                          <button @click="unblockCandidate()" class="block-chat-btn">Bỏ chặn</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="position-relative mt-5 text-muted text-sm" style="top: 25%;">
                      <p class="text-sm text-center mt-4">Ứng viên không khả dụng!</p>
                    </div>
                  </div>
                </template>
                <template v-else>
                    <div class="position-relative mt-5 text-muted text-sm" style="top: 25%;">
                        <img style="width: 200px;" class="d-block m-auto" src="../assets/no-conversation.png" />
                        <p class="text-sm text-center mt-4">Bạn không có cuộc trò chuyện nào...</p>
                    </div>
                </template>
            </div>
            <div v-if="!embedMode && (!isMobile || ((isMobile && !hasChatQuery) || (isMobile && ['Applied', 'ConversationInfo'].includes(currentMobileTab))))" :class="(isMobile && !hasChatQuery) || isMobile && (['Applied', 'ConversationInfo'].includes(currentMobileTab)) ? 'col-12' : 'col-3'" class="h-100 border-left p-0">
                <template v-if="conversations.length > 0 && currentConversation.conversation._id">
                    <div :style="{height: isMobile ? '100%' : 'auto'}" v-if="(!isFetchingMessage && user.agree_term) || (isMobile && currentMobileTab == 'ConversationInfo')">
                        <div :style="{height: isMobile ? '100%' : 'auto'}" id="candidate-tab" class="company-info border-bottom p-3">
                            <h5 v-if="!isMobile" class="text-uppercase font-weight-bold text-sm mb-4">Ứng viên</h5>
                            <div class="media" :class="{'border-bottom': isMobile}">
                                <i @click="currentMobileTab = null" v-if="isMobile && hasChatQuery" style="height: 45px; width: 45px; text-align: center; padding: 13px" class="mt-1 mr-2 text-muted fas fa-arrow-left"></i>
                                <avatar class="mr-2 conversations-avatar rounded-circle" :src="(currentConversation.user.is_uploaded_avatar && currentConversation.user.avatar) ? currentConversation.user.avatar : null" :username="currentConversation.user.fullname ? currentConversation.user.fullname : 'Người dùng TopCV'"></avatar>
                                <div class="mt-2">
                                    <h5 style="font-size: 14px" class="font-weight-bold mb-0">{{ currentConversation.user.fullname }}</h5>
                                    <div class="mt-0">
                                        <p class="ttext-muted"><small>{{ currentConversation.user.email || 'Người dùng TopCV' }}</small></p>
                                    </div>
                                </div>
                            </div>
                            <template v-if="appliedJobsByUser.length > 0">
                                <h5 class="text-uppercase font-weight-bold text-sm mb-0 mt-3">Việc làm đã ứng tuyển</h5>
                                <div style="max-height: 250px; overflow: hidden auto" :style="{maxHeight: isMobile ? '50%' : '250px'}" class="list-applied-jobs">
                                    <div v-for="(applied, index) in listAppliedJobsByUser" :key="index" style="background: #fafafa; border-radius: 6px" class="applied-item mt-3 py-2 px-3">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <small style="font-size: 11px" class="text-muted d-block">{{ timeAgo(applied.created_at) }}</small>
                                                <p v-tooltip="applied.title" class="my-1 text-sm text-dark">{{ applied.title.length > 20 ? applied.title.substr(0, 20) + '...' : applied.title }}</p>
                                                <small style="font-size: 11px" class="text-muted d-block">Mã tin #{{ applied.job_id }}</small>
                                            </div>
                                            <div>
                                                <a :href="applied.cv_apply_url" target="_blank" class="mr-1 mt-3 btn text-theme" style="font-size: 12px; padding: 3px 10px; border: 1px solid #212f3f; border-radius: 25px">Xem CV</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p v-if="appliedJobsByUser.length > 2" @click="showMoreAppliedJobByUser" class="mb-0 mt-3 text-muted text-center text-sm">
                                            {{ showAllAppliedJobByUser ? 'Rút gọn' : 'Xem thêm' }} <i class="fas" :class="showAllAppliedJobByUser ? 'fa-caret-up' : 'fa-caret-down'"></i>
                                        </p>
                                    </div>
                                </div>
                            </template>
                            <template v-if="supperFastJobs.length > 0">
                                <h5 class="text-uppercase font-weight-bold text-sm mb-0 mt-3">Tìm việc siêu tốc</h5>
                                <div style="max-height: 250px; overflow: hidden auto" :style="{maxHeight: isMobile ? '50%' : '250px'}" class="list-applied-jobs">
                                    <div v-for="(applied, index) in listSuperFastJobsByUser" :key="index" style="background: #fafafa; border-radius: 6px" class="applied-item mt-3 py-2 px-3">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <small style="font-size: 11px" class="text-muted d-block">{{ timeAgo(applied.created_at) }}</small>
                                                <p v-tooltip="applied.title" class="my-1 text-sm text-dark">{{ applied.fullname.length > 20 ? applied.fullname.substr(0, 20) + '...' : applied.fullname }}</p>
                                            </div>
                                            <div>
                                                <a :href="applied.cv_url.replace(/(\?iframe=true|\&iframe=true)/g, '')" target="_blank" class="mr-1 mt-3 btn text-theme" style="font-size: 12px; padding: 3px 10px; border: 1px solid #212f3f; border-radius: 25px">Xem CV</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p v-if="supperFastJobs.length > 2" @click="showMoreSuperFastJobByUser" class="mb-0 mt-3 text-muted text-center text-sm">
                                            {{ showAllSuperFastJobByUser ? 'Rút gọn' : 'Xem thêm' }} <i class="fas" :class="showAllSuperFastJobByUser ? 'fa-caret-up' : 'fa-caret-down'"></i>
                                        </p>
                                    </div>
                                </div>
                             </template>
                        </div>
                    </div>
                    <div v-else class="p-3">
                        <h5 v-if="!isMobile" class="text-uppercase font-weight-bold text-sm mb-4">Ứng viên</h5>
                        <content-loader
                            :width="460"
                            :height="60"
                            :speed="2"
                            primaryColor="#f3f3f3"
                            secondaryColor="#ecebeb"
                            class="mt-1"
                        >
                            <rect x="68" y="6" rx="10" ry="10" width="300" height="20" />
                            <rect x="69" y="37" rx="10" ry="10" width="180" height="17" />
                            <circle cx="28" cy="31" r="28" />
                        </content-loader>
                    </div>
                </template>
                <template v-if="!isMobile || (isMobile && currentMobileTab == 'Applied')">
                    <div class="position-relative">
                        <div class="side-bar-group mt-1" style="padding: 11px">
                            <div id="latest-candidate-title">
                                <h4 class="mb-1 side-bar-group-title text-dark">Ứng viên khác</h4>
                                <small class="text-muted">Ứng tuyển vào tin tuyển dụng của bạn trong 7 ngày qua</small>
                            </div>
                            <template v-if="isFetchingCanidate || !user.agree_term">
                                <content-loader
                                    v-for="n in 7"
                                    :key="n"
                                    :width="460"
                                    :height="60"
                                    :speed="2"
                                    primaryColor="#f3f3f3"
                                    secondaryColor="#ecebeb"
                                    class="mt-3"
                                >
                                    <rect x="68" y="6" rx="10" ry="10" width="300" height="20" />
                                    <rect x="69" y="37" rx="10" ry="10" width="180" height="17" />
                                    <circle cx="28" cy="31" r="28" />
                                </content-loader>
                            </template>
                            <ul v-else style="overflow: hidden auto;" :style="{maxHeight: latestCandidateTabHeight + 'px'}" class="side-bar-group-menu mt-3">
                                <div v-for="(candidate, index) in latestCandidates" :key="index" class="mb-3">
                                    <div class="media">
                                        <div class="mt-2 mr-3">
                                            <avatar class="conversations-avatar rounded-circle" :src="(candidate.is_uploaded_avatar && candidate.avatar) ? candidate.avatar : null" :username="candidate.fullname ? candidate.fullname : ''"></avatar>
                                        </div>
                                        <div class="w-100">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <a class="text-dark font-weight-bold" style="font-size: 14px" href="#">{{ candidate.fullname }}</a>
                                                    <small v-tooltip="candidate.job_title" style="font-size: 12px" class="d-block text-muted font-weight-bold">{{ candidate.job_title.length > 20 ? candidate.job_title.substr(0, 20) + '...' : candidate.job_title }}</small>
                                                    <small style="font-size: 11px" class="d-block text-muted">{{ timeAgo(candidate.created_at) }}</small>
                                                </div>
                                                <div class="mr-2 mt-3">
                                                    <button @click="openNewChat(candidate.user_id, $event)" class="btn btn-sm btn-theme">
                                                        Nhắn tin
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <div class="position-relative">
                            <div style="position: fixed; bottom: 0px; background: #fff; padding: 10px 20px;" class="w-100 col-3">
                                <a target="_blank" :href="cvManagementUrl" style="border: 1px solid #212F3F;border-radius: 20px;color: #212F3F; font-size: 14px; padding: 7px 10px" class="d-block text-center no-decoration">Quản lý hồ sơ ứng tuyển</a>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <audio class="d-none" id="ting">
                <source src="../assets/ting.mp3" type="audio/ogg">
                <source src="../assets/ting.mp3" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>

      <div class="modal" v-if="message" id="modal-notice" aria-hidden="true">
        <div class="modal-dialog modal-small" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" style="width:100%;color:#212F3F;text-align:center;font-size: 15px">TopCV
                Connect</h5>
            </div>
            <div class="modal-body">
              <p class="text-sm">{{ message }}</p>
              <div class="d-flex flex-row-reverse">
                <button data-dismiss="modal" class="btn btn-theme btn-md">Đóng</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMobile && !hasChatQuery" class="bottom-tab-mobile">
        <div class="d-flex justify-content-center">
          <div :class="{active: currentMobileTab == 'Conversations'}" @click="currentMobileTab = 'Conversations'"
               class="mx-5 text-center">
            <i style="font-size: 20px" class="d-block fab fa-facebook-messenger"></i>
            <p class="mb-0 mt-2 text-sm">Chat</p>
          </div>
          <div :class="{active: currentMobileTab == 'Applied'}" @click="currentMobileTab = 'Applied'"
               class="mx-5 text-center">
            <i style="font-size: 20px" class="d-block fas fa-users"></i>
            <p class="mb-0 mt-2 text-sm">Ứng viên</p>
          </div>
        </div>
      </div>
    </div>
    <AgreeTerm :is_agree_term="user.agree_term" @onSubmit="scaleStyle()"/>
    <ConfirmBlockCandidate :show-candidate="showBlockCandidate" :candidate-name="blockCandidateName" @onSubmitModal="blockCandidate()" @onCloseModal="showBlockCandidate=false"/>
    <!-- <SurveyForm :user="user" :is_agree_term="user.agree_term" :is_submitted_survey_form="user.is_submitted_survey_form" @onSubmit="scaleStyle()" /> -->
  </div>
</template>

<style scoped>
.btn-light {
  border: none;
  color: #868D94;
  background: #fff;
    height: 40px;
    width: 100px;
}

.btn-light:hover {
  background: #fff;
  border-radius: 0;
}

.btn-active-tab {
  background: #fff;
  color: #00b14f;
  border-bottom: 2px solid #00b14f;
  border-radius: 0;
}

.icon-shape {
  background-color: rgba(0, 0, 0, .04);
  padding: 8px;
  border-radius: 50%;
  color: #555;
}

.h-100vh {
  height: 100vh;
}

.h-80vh {
  height: 80vh;
}

.search-input {
  background-color: transparent;
  border: 1px solid #f2f2f2;
  height: 45px;
  border-radius: 25px;
  padding-left: 40px;
  color: #333;
  font-size: 14px;
}

.search-icon {
  line-height: 45px;
  left: 32px;
  color: rgb(158, 156, 156);
}

.no-outline:focus {
  outline: none;
}

.list-conversations {
  overflow: hidden auto;
}

.conversation-item {
  cursor: pointer;
  padding: 10px;
}

.conversation-item:hover {
  background: #ecf0f1;
}

.conversations-avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  background-size: contain !important;
  background-position: center !important;
  border: 1px solid #f1f2f7;
  background: #fff;
}

.conversations-username {
  font-size: 14px;
}

.conversations-demo-message {
  font-size: 12px;
  margin-bottom: 0;
}

.not-read-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #01b14f;
  margin: auto 0;
}
.not-read-dot-button {
    position: relative;
    top: -30px;
    left: 75px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #DA4538;
  margin: auto 0;
}

.not-read-message {
  font-weight: bold;
}

.active-chat {
  background-color: #fafafa;
}

.chat-body {
  overflow: hidden auto;
}

.chat-body .chat-avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
  align-self: flex-end;
}

.chat-body .chat {
  margin-bottom: 15px;
}

.chat-body .chat .message {
  max-width: 360px;
  font-size: 14px;
  color: #333;
  padding: 7px 12px;
  margin-bottom: 1px;
  white-space: pre-wrap;
  line-height: 1.34rem;
  display: inline-block;
  width: fit-content;
}

.chat-body .chat .border-message {
  border-radius: 18.2px !important;
}

.chat-body .chat-left .message {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 18.2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 18.2px;
  background-color: #f1f0f0;
  display: block;
}

.chat-body .chat-left .first-message {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 18.2px;
  border-top-left-radius: 18.2px;
  border-top-right-radius: 18.2px;
}

.chat-body .chat-left .last-message {
  border-bottom-left-radius: 18.2px;
  border-bottom-right-radius: 18.2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 18.2px;
}

.chat-body .chat-right .message {
  background-color: #00b14f;
  color: #fff;
  display: block;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 18.2px;
  border-top-left-radius: 18.2px;
  display: block;
}

.chat-body .chat-right .first-message {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 18.2px;
  border-bottom-left-radius: 18.2px;
  border-top-left-radius: 18.2px;
}

.chat-body .chat-right .last-message {
  border-bottom-right-radius: 18.2px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 18.2px;
  border-top-left-radius: 18.2px;
}

.chat-body .chat-right {
  display: flex;
  justify-content: flex-end;
}

.chat-input {
  display: flex;
  flex-direction: column-reverse;
  background-color: #fff;
  height: 60px;
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.chat-input-item {
  line-height: 60px;
}

.text-theme {
  color: #212f3f;
}

.text-theme-2 {
  color: #01b14f;
}

.chat-input-box {
  background-color: rgba(0, 0, 0, .04);
  border: 0px;
  height: 36px;
  line-height: initial;
  border-radius: 25px;
  padding-left: 15px;
  color: #333;
  padding: 10px 13px;
  font-size: 14px;
}

.btn-theme {
  color: #00B14F;
  background: #E4F6EC;
  border-radius: 25px;
  font-size: 13px;
  padding: 3px 10px;
}

.bg-theme {
  color: #fff !important;
  background-color: #28A27A !important;
  background-repeat: no-repeat, no-repeat !important;
  background-size: 240% 100%, 100% 100% !important;
  background-position: 120% 0, center !important;
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, .1) 0, rgba(255, 255, 255, .05) 45%, rgba(255, 255, 255, 0) 55%), -webkit-linear-gradient(top left, #28A27A, #4BB05D) !important;
  background-image: linear-gradient(to right, rgba(255, 255, 255, .1) 0, rgba(255, 255, 255, .05) 45%, rgba(255, 255, 255, 0) 55%), linear-gradient(to bottom right, #28A27A, #4BB05D) !important;
}

.avatar-infor {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.loading-message {
  position: absolute;
  top: 40%;
  margin-bottom: 20px;
  left: 50%;
}

.message-status-icon i {
  font-size: 11px;
  position: absolute;
  bottom: 7px;
  color: rgb(212, 211, 211) !important;
}

.text-sm {
  font-size: 13px;
}

.text-underline {
  text-decoration: underline !important;
}

a:hover {
  cursor: pointer;
}

.no-underline:hover {
  text-decoration: none !important;
}

@font-face {
  font-family: NotomojiColor;
  font-weight: 400;
  src: url(https://cdn.glitch.com/61908de1-dd0a-4359-a54b-6cb6d41bb5fd%2FNotoColorEmoji.ttf?1513108808150) format("truetype");
}

.side-bar-group {
  padding: 14px;
}

.side-bar-group-title {
  color: rgba(0, 0, 0, .34);
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}

.side-bar-group-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-bar-group-item {
  font-size: 13px;
  color: rgba(0, 0, 0, .8);
}

.side-bar-group-item-title {
  display: flex;
  align-items: center;
}

.text-ssm {
  font-size: 12px;
}
</style>
<style>
    .cursor-pointer{
      cursor:pointer;
    }
    .border-bottom{
        border-bottom: 1px solid #EEEEEE !important;
    }
    .border-left{
        border-left: 1px solid #EEEEEE !important;
    }
    .border-right{
        border-right: 1px solid #EEEEEE !important;
    }
    .border-top{
        border-top: 1px solid #EEEEEE !important;
    }
    .border{
        border: 1px solid #EEEEEE !important;
    }
    .chat-right .text-link {
        color: #fff !important;
        text-decoration: underline !important;
    }
    .chat-left .text-link {
        color: #000 !important;
        text-decoration: underline !important;
    }
    .message {
        word-break: break-word;
    }
    .message-2 {
      padding: 8px;
      background: #E5F7ED;
      border-radius: 8px;
      color: #303235;
      margin-top: 10px;
    }
    .message-3 {
      padding: 8px;
      background: #fff;
      border-radius: 8px;
      color: #303235;
      margin-top: 10px;
    }
    .conversations-demo-message a{
        color: inherit !important;
        text-decoration: underline !important;
    }
    .emoji-picker {
        position: absolute;
        bottom: 60px;
        left: 50px;
        background: #fff !important;
    }
    .emoji {
        border: 0px !important;
    }
    .container-search input {
        background: #fff !important;
        border-radius: 0px !important;
        margin: 10px 0px;
    }
    #Categories {
        background: #fff !important;
    }
    .single-border {
        border-top: 1px solid rgba(0, 0, 0, .10);
    }
    .tooltip {
        display: block !important;
        z-index: 10000;
    }

.tooltip .tooltip-inner {
  background: rgb(0, 0, 0, .8);
  color: white;
  border-radius: 16px;
  padding: 3px 10px 4px;
  font-size: 12px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: rgb(0, 0, 0, .8);
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.no-decoration:hover {
  text-decoration: none !important;
}

#chat-header {
  box-shadow: 0px 2px 5px #0000000D;
  border-bottom: 1px solid #EEEEEE !important;
}

.list-item-content {
  font-size: 14px;
}

.online-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #31a24c;
  right: 3px;
  bottom: 0px;
  border: 2px solid white;
}

.dropdown-menu {
  border: 0px !important;
  box-shadow: 0 12px 28px 0 rgb(0, 0, 0, .2), 0 2px 4px 0 rgb(0, 0, 0, .1), inset 0 0 0 1px rgb(255, 255, 255, .5);
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.custom-control-label {
  font-size: 14px;
}

.custom-control-label::before {
  cursor: pointer;
  background-color: #ced0d4 !important;
  border: #ced0d4 solid 1px !important;
}

.custom-switch .custom-control-label::after {
  cursor: pointer;
  background: #fff !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  cursor: pointer;
  background: #00B14F !important;
  border: #00B14F solid 1px !important;
}

#chat-box-input {
  transition: height 100ms ease-in;
  resize: none;
  bottom: 11px;
  max-height: 62px !important;
  min-height: 36px !important;
  overflow: auto;
}

.comments-box {
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 5px;
  background: #fff;
  font-size: 13px;
}

.bottom-tab-mobile {
  position: fixed;
  bottom: 0px;
  background: #ffff;
  width: 100%;
  padding: 15px;
  border-top: 1px solid #eee;
}

.bottom-tab-mobile {
  color: #6c757d;
}

.bottom-tab-mobile .active {
  color: #00B14F !important;
}

@media only screen and (max-width: 1450px) {
  #home-back {
    display: none;
  }
}
.chat-input.block-chat-input {
  height: auto !important;
  bottom: 0;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05);
}
    .flex-grow-1.block-chat-message {
      padding-left: 12px;
    }
    .block-chat-image{
      width: 40px;
      height: 40px;
    }
    .block-msg{
      font-size: 12px;
      line-height: 16px;
      margin: 0;
   }
    p.block-msg{
      font-weight: bold;
      margin: 0 0 4px 0;
    }
    .block-chat-button {
      width: 88px;
      height: 40px;
    }
    button.block-chat-btn {
      padding: 9px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: 100%;
      background: #00B14F;
      border: none;
      border-radius: 5px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
      color: #FFFFFF;
      cursor: pointer;
      outline: none;
    }
</style>
<script>
import $ from 'jquery';
import conversationApi from '@/api/conversationApi';
import authApi from '@/api/authApi';
import appliedJobApi from '@/api/appliedJobApi';
import {ContentLoader} from 'vue-content-loader';
import Loading from '@/components/Loading';
import SocketConfig from '@/config/socket';
import {mapState} from 'vuex';
import {VEmojiPicker} from 'v-emoji-picker';
import LoadingCircle from '@/components/LoadingCircle.vue';
import moment from 'moment';
import Avatar from 'vue-avatar';
import AgreeTerm from '@/components/AgreeTerm';
import ConfirmBlockCandidate from '@/components/ConfirmBlockCandidate';
import SurveyForm from '@/components/SurveyForm';
import taAction from '@/config/ta_action';

export default {
  data() {
    return {
      defaultTitle: 'TopCV Connect - New way to follow your your chance. More engage, more success',
      conversationTitleMarked: null,
      focused: true,
      showBlockCandidate: false,
      blockCandidateName: '',
      filter: {
        limit: 0,
      },
      isFetchingConversation: false,
      conversations: [],
      showedConversations: [],
      unreadConversations: [],
      currentConversation: {
        user: {
          apply: {},
        },
        conversation: {},
        messages: [],
      },
      isFetchingMessage: false,
      text: '',
      isLoadingMoreMessage: false,
      cursor_index: 0,
      showChat: false,
      showEmoji: false,
      groupMessages: [
        [],
      ],
      splitGroupMessageTime: 10,
      latestCandidates: [],
      latestCandidateTabHeight: 300,
      appliedJobsByUser: [],
      showAllAppliedJobByUser: false,
      showAllSuperFastJobByUser: false,
      preventFetchConversation: false,
      showNewChat: false,
      newChat: {},
      isLoadingNewChat: false,
      isFetchingNewChat: false,
      isFocusedSearchInput: false,
      isSearchingConversation: false,
      conversationsSearched: [],
      message: '',
      isFetchingCanidate: false,
      isLoadingMoreConversation: false,
      isFetchedAllConversations: false,
      isFetchedAllMessages: false,
      isMobile: false,
      hasChatQuery: false,
      currentMobileTab: 'Conversations',
      supperFastJobs: [],
      currentCandidate: null,
      activeTab: 'all',
      expandMessage: {},
    };
  },
  async created() {
    this.getLatestAppliedJob();
    this.bindWindowEvent();
    await this.fetchListConversations();
    await this.fetchConversationByRoute();
    this.getActionByRouteName();
    this.listenSocketData();
  },
  mounted() {
    this.isMobile = document.body.offsetWidth <= 900;
    this.isHasChatQuery();
    window.onresize = () => {
      this.scaleStyle();
      this.isMobile = document.body.offsetWidth <= 900;
    };
    window.onfocus = () => {
      this.focused = true;
      if (this.currentConversation.conversation) {
        this.markAsSeenMessage(this.currentConversation.conversation._id, null);
      }
    };
    window.onblur = () => {
      this.focused = false;
    };
    document.addEventListener('keyup', function(evt) {
      if (evt.keyCode === 27) {
        let searchInput = document.getElementById('search-input');
        if (searchInput) {
          searchInput.blur();
          this.isFocusedSearchInput = false;
        }
      }
    });
    setInterval(() => {
      this.$forceUpdate();
    }, 60000);
    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  },
  components: {
    SurveyForm,
    AgreeTerm,
    ConfirmBlockCandidate,
    ContentLoader,
    Loading,
    VEmojiPicker,
    LoadingCircle,
    Avatar,
  },
  computed: {
    cvManagementUrl() {
      return process.env.VUE_APP_EMPLOYER_V2_URL + '/cvs-management';
    },
    ...mapState('auth', {
      user: state => state.user,
      isLogined: state => state.isLogined,
    }),
    avgTimeAnswer() {
      if (!this.currentConversation || !this.currentConversation.user) {
        return 0;
      }
      return this.currentConversation.user.total_duration_reply_times / this.currentConversation.user.total_reply_times;
    },

    embedMode() {
      return this.$route.query.embed;
    },
    isFocusedTab() {
      let visibleStatus = {
        visible: true,
        hidden: false,
      };
      return visibleStatus[document.visibilityState] || false;
    },
    isFocusChatInput() {
      return document.activeElement == document.getElementById('chat-box-input');
    },
    listAppliedJobsByUser() {
      const limitShowRecord = 2;
      const numberOfRecord = this.showAllAppliedJobByUser ? this.appliedJobsByUser.length : limitShowRecord;
      return this.appliedJobsByUser.slice(0, numberOfRecord) || [];
    },
    listSuperFastJobsByUser() {
      const limitShowRecord = 2;
      const numberOfRecord = this.showAllSuperFastJobByUser ? this.supperFastJobs.length : limitShowRecord;
      return this.supperFastJobs.slice(0, numberOfRecord) || [];
    },
  },
  watch: {
    conversations: {
      deep: true,
      handler() {
        if (this.activeTab === 'all') {
          this.showedConversations = this.conversations;
          this.unreadConversations = this.conversations.filter(
            conversation => !this.isSeenLastMessage(conversation.conversation)
          )
        }
        if (this.activeTab === 'unread') {
          const newUnreadConversations = this.conversations.filter(
            conversation => !this.isSeenLastMessage(conversation.conversation)
          )
          let difference = newUnreadConversations.filter(conversation => !this.unreadConversations.find(tempConversation => tempConversation.conversation._id === conversation.conversation._id));
          if (difference.length > 0) {
            this.unreadConversations = difference.concat(this.unreadConversations);
            this.showedConversations = this.unreadConversations;
          }

        }
      },
    },
  },
  methods: {
    handleChangeTab(name) {
      this.activeTab = name;
      this.resetCurrentConversation();
      if (this.activeTab === 'all') {
        this.showedConversations = this.conversations;
        this.unreadConversations = this.conversations.filter(
            conversation => !this.isSeenLastMessage(conversation.conversation)
        )
        this.showedConversations = this.conversations;
      } else {
        this.showedConversations = this.unreadConversations;
      }
      this.fetchConversation(this.showedConversations[0], true);
    },
    bindWindowEvent() {
      const currentTabId = sessionStorage.getItem('topcv_connect_tab_id');
      const lastTabId = localStorage.getItem('topcv_connect_last_tab_id');
      const listTabIds = JSON.parse(localStorage.getItem('topcv_connect_last_tabs')) || [];

      window.addEventListener('beforeunload', (e) => {
        let tabIndex = listTabIds.indexOf(currentTabId);
        listTabIds.splice(tabIndex, 1);
        localStorage.setItem('topcv_connect_last_tabs', JSON.stringify(listTabIds));

        if (listTabIds.length >= 1) {
          if (currentTabId == lastTabId) {
            localStorage.setItem('onCloseServerTab', JSON.stringify({
              triggerTabId: listTabIds[listTabIds.length - 1],
              tabId: currentTabId,
            }));
            localStorage.removeItem('onCloseServerTab');
          }
        }
      });
      window.addEventListener('storage', (e) => {
        if (e.key != 'onCloseServerTab') return;
        const messageDriven = JSON.parse(e.newValue);
        if (!messageDriven) return;
        if (messageDriven.triggerTabId == currentTabId) {
          sessionStorage.setItem('topcv_connect_tab_id', messageDriven.tabId);
          let oldTabIndex = listTabIds.indexOf(currentTabId);
          listTabIds.splice(oldTabIndex, 1);
          localStorage.setItem('topcv_connect_last_tabs', JSON.stringify(listTabIds));
        }
      });
    },
    logout() {
      // var ckDomain;
      // for (var ckDomain = window.location.hostname.split("."); 2 < ckDomain.length;){
      //     ckDomain.shift();
      // }
      // ckDomain = ";domain=" + ckDomain.join(".");
      // document.cookie = "employer_sso_token=; expires=Thu, 07-Mar-2047 20:22:40 GMT; path=/" + ckDomain;
      // document.cookie = "employer_sso_token=; expires=Thu, 07-Mar-2047 20:22:40 GMT; path=/";
      localStorage.removeItem('employer_sso_token');
      window.location.assign('http://tuyendung.topcv.vn/');
    },

    openConversationInfo() {
      this.currentMobileTab = 'ConversationInfo';
      this.$forceUpdate();
    },
    mobileBack() {
      this.showNewChat = false;
      this.currentMobileTab = 'Conversations';
      this.changeBrowserQueriesUrl('conversations');
      this.isHasChatQuery();
      this.resetCurrentConversation();
      this.scaleStyle();
    },
    isHasChatQuery() {
      let url = new URL(document.URL);
      if (url) {
        if (url.search) {
          this.hasChatQuery = url.search.includes('chat=');
        } else {
          this.hasChatQuery = url.pathname.includes('/conversations/new/');
        }
      } else {
        this.hasChatQuery = false;
      }
    },
    resetCurrentConversation() {
      this.currentConversation = {
        user: {
          apply: {},
        },
        conversation: {},
        messages: [],
      };
    },
    async openNewChat(user_id, element = null) {
      try {
        if (element) {
          element.target.innerText = 'Đang tải...';
        }
        this.preventFetchConversation = true;
        this.isLoadingNewChat = true;
        this.isFetchingMessage = true;
        let {embed} = this.$route.query;
        this.changeBrowserQueriesUrl(`conversations/new/${user_id}`);
        if (this.isMobile) {
          this.currentMobileTab = 'Conversations';
        }
        let {data} = await conversationApi.findConversationByUserId({
          user_id,
        });
        this.isLoadingNewChat = false;
        this.isFetchingMessage = false;
        if (!data.success) {
          if (data.message) {
            $('#modal-notice').modal({
              keyboard: false,
              backdrop: 'static',
            });
            this.message = data.message;
          }
          return this.$router.push({name: 'app.conversations', query: this.$route.query});
        }
        this.currentCandidate = Object.keys(data?.user ?? {})?.length ? {...data.user} : null;
        if (data.status === 'VALID_CONVERSATION') {
          this.showNewChat = false;
          return this.$router.push({
            name: 'app.conversations', query: {
              chat: data.conversation_id,
              t: new Date().getTime(),
              embed,
            },
          });
        }
        if (data.status === 'INVALID_CONVERSATION') {
          this.resetCurrentConversation();
          if (!data.can_chat) {
            return this.$router.push({name: 'app.conversations', query: this.$route.query});
          }
          this.showNewChat = data.can_chat;
          this.newChat = data.user;
          if (this.isMobile) {
            this.isHasChatQuery();
          }
          this.$nextTick(() => {
            this.scaleStyle();
          });
        }
        if (element) {
          element.target.innerText = 'Nhắn tin';
        }
      } catch (e) {

      }
    },
    async getActionByRouteName() {
      const routeName = this.$route.name;
      const routeNameMapping = {
        newConversation: 'app.new-conversation',
      };
      if (routeName == routeNameMapping.newConversation) {
        const {user_id} = this.$route.params;
        if (user_id) {
          this.openNewChat(user_id);
        }
      }
    },
    async getLatestAppliedJob() {
      this.isFetchingCanidate = true;
      let {data} = await appliedJobApi.getLatestAppliedJob();
      this.isFetchingCanidate = false;
      if (data.status == 'success') {
        this.latestCandidates = data.candidates;
      }
    },
    async getAppliedJobByUser(user_id) {
      let {data} = await appliedJobApi.getAppliedJobByUser({
        user_id,
      });
      if (data.status == 'success') {
        this.appliedJobsByUser = data.candidates;
        this.$nextTick(() => {
          this.scaleStyle();
        });
      }
    },
    async getSuperJobsByUser(user_id) {
      let {data} = await appliedJobApi.getFastJobByUser({
        user_id,
      });
      if (data.status == 'success') {
        this.supperFastJobs = data.fast_jobs;
        this.$nextTick(() => {
          this.scaleStyle();
        });
      }
    },
    onSelectEmoji(emoji) {
      this.text += emoji.data;
    },
    scaleStyle() {
      try {
        // const stickyBanner = document.getElementById('sticky-banner');
        const sloganChat = document.getElementById('slogan');
        const bodyChat = document.getElementById('chat-body');
        const headerChat = document.getElementById('chat-header');
        const footerChat = document.getElementById('chat-footer');
        bodyChat.style.height = document.body.offsetHeight -
            (headerChat.offsetHeight + footerChat.offsetHeight + sloganChat.offsetHeight) + 'px';
        const ele = document.getElementById('chat-box-input');
        ele.addEventListener('keydown', async (e) => {
          const keyCode = e.which || e.keyCode;
          if (keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            await this.startConversationOrSendMessage();
            document.getElementById('chat-box-input').style.height = '36px';
          }
        });
        window.addEventListener('click', (e) => {
          const emojiPicker = document.getElementsByClassName('emoji-picker');
          const emojiIcon = document.getElementById('emoji-icon');
          if (emojiPicker && emojiPicker[0] && !emojiPicker[0].contains(e.target) && emojiIcon &&
              !emojiIcon.contains(e.target)) {
            this.showEmoji = false;
          }
        });
        this.autoResizeChatBox();
        this.latestCandidateTabHeight = screen.availHeight -
            (document.getElementById('candidate-tab').offsetHeight + 120 +
                document.getElementById('latest-candidate-title').offsetHeight) - 30;
      } catch (e) {

      }
    },
    autoResizeChatBox() {
      const self = this;
      $('#chat-box-input').on('input', function() {
        this.style.height = 'auto';
        let chatTextLine = self.text.split('\n').length;
        chatTextLine = chatTextLine == 0 ? 1 : chatTextLine;
        this.style.height = (chatTextLine * 36) + 'px';
      });
    },
    gotoBottomOfChat() {
      const bodyChat = document.getElementById('chat-body');
      bodyChat.scrollTop = bodyChat.scrollHeight;
    },
    async fetchListConversations() {
      if (this.$route.query.embed) {
        return;
      }
      this.isFetchingConversation = true;
      let {data} = await conversationApi.fetchList({
        cursor_index: 0,
      });
      this.isFetchingConversation = false;
      this.conversations = data.conversations;
      this.unreadConversations = this.conversations.filter(
          conversation => !this.isSeenLastMessage(conversation.conversation));
      if (this.activeTab === 'all') {
        this.showedConversations = this.conversations;
      } else {
        this.showedConversations = this.unreadConversations;
      }
      const {agree_term} = this.user;
      if (!this.$route.query.chat && this.conversations.length > 0 && !this.preventFetchConversation && agree_term &&
          !this.isMobile) {
        let conversation = this.conversations[0];
        this.fetchConversation(this.conversations[0], true);
      }
    },
    async fetchConversationByRoute() {
      let conversationId = this.$route.query.chat;
      if (conversationId) {
        this.showNewChat = false;
      }
      let {embed} = this.$route.query;
      if (conversationId && !this.preventFetchConversation) {
        this.isFetchingMessage = true;
        this.showChat = true;
        this.currentConversation.conversation._id = conversationId;
        this.$forceUpdate();
        let {data} = await conversationApi.fetchOne({
          conversation_id: conversationId,
          cursor_index: 0,
          fetch_user: true,
        });
        this.isFetchingMessage = false;
        if (!data.success) {
          return this.$router.push({name: 'app.conversations', query: {embed}});
        }
        this.currentConversation.conversation = data.conversation;
        this.getAppliedJobByUser(data.user?.id);
        this.getSuperJobsByUser(data.user?.id);
        this.markAsSeenMessage(data.conversation._id, null);
        this.markAsSeenConversation(data.conversation);
        this.loadConversationMessage(data.messages);
        this.currentConversation.user = {
          ...data.user,
          conversation: data.conversation,
        };
        this.mergeGroupMessagesReset();
        if (this.$route.query.embed) {
          this.conversations.push(this.currentConversation);
        }
        this.$nextTick(() => {
          this.scaleStyle();
          this.gotoBottomOfChat();
        });
        this.currentCandidate = Object.keys(data?.user ?? {})?.length ? {...data.user} : null;
      }
    },
    async fetchConversation(conversation, fetch_user = true) {
      this.cursor_index = 0;
      this.showNewChat = false;
      this.isFetchedAllMessages = false;
      this.appliedJobsByUser = [];
      this.supperFastJobs = [];
      this.currentConversation.conversation._id = conversation.conversation._id;
      this.$forceUpdate();
      this.changeBrowserQueriesUrl('conversations', {
        chat: conversation.conversation._id,
      });
      this.isHasChatQuery();
      this.text = '';
      this.isFetchingMessage = true;
      let bodyData = {
        conversation_id: conversation.conversation._id,
        cursor_index: this.cursor_index,
      };
      if (fetch_user) bodyData.fetch_user = fetch_user;
      let {data} = await conversationApi.fetchOne(bodyData);
      this.isFetchingMessage = false;
      if (!data.success) {
        this.currentCandidate = null;
        return this.$router.push({name: 'app.conversations', query: this.$route.query});
      }
      if (fetch_user) {
        this.currentConversation.user = {
          ...data.user,
          conversation: data.conversation,
        };
      }
      this.currentConversation.conversation = data.conversation;
      this.getAppliedJobByUser(data.user?.id);
      this.getSuperJobsByUser(data.user?.id);
      this.markAsSeenMessage(data.conversation._id, null);
      this.markAsSeenConversation(data.conversation);
      this.loadConversationMessage(data.messages);
      this.mergeGroupMessagesReset();
      this.showChat = true;
      this.$nextTick(() => {
        this.scaleStyle();
        this.gotoBottomOfChat();
      });
      this.currentCandidate = Object.keys(data?.user ?? {})?.length ? {...data.user} : null;
    },
    markAsSeenConversation(conversationReference) {

      let conversationSelected = this.conversations.find(
          (conversation) => conversation.conversation._id == conversationReference._id);
      if (conversationSelected && conversationSelected.conversation.last_seen_employer_message) {
        conversationSelected.conversation.last_seen_employer_message._id = conversationSelected.conversation.last_message_id = conversationReference.last_message_id;
        if (!conversationSelected.conversation.last_seen_employer_message &&
            conversationSelected.conversation.last_seen_user_message) {
          window.ta(taAction.UVRequestChat, {
            oth: {
              request_id: conversationSelected.conversation._id,
              created_at: conversationSelected.conversation.created_at,
              user_id: conversationSelected.conversation.user_id,
              employer_id: conversationSelected.conversation.employer_id,
              seen_time: moment(),
            },
          });
        }

      }

    },
    loadConversationMessage(messages) {
      let messagesAvaiable = {};
      this.currentConversation.messages = messages.map((message, index) => {
        message.is_last_seen = false;
        if (this.currentConversation.conversation.last_seen_user_message && new Date(message.created_at).getTime() <=
            new Date(this.currentConversation.conversation.last_seen_user_message.time).getTime() &&
            message.employer_send_id) {
          messagesAvaiable = {
            index,
            message,
          };
        }
        return message;
      });
      if (messagesAvaiable && messagesAvaiable.index != undefined) {
        const lastMesssageIndex = messages.length - 1;
        if (messages[lastMesssageIndex].user_send_id) {
          this.currentConversation.messages[lastMesssageIndex].is_last_seen = true;
        } else {
          this.currentConversation.messages[messagesAvaiable.index].is_last_seen = true;
        }
        let messageNotSeen = this.currentConversation.messages.slice(messagesAvaiable.index + 1,
            this.currentConversation.messages.length);
        if (messageNotSeen.length > 0) {
          messageNotSeen = messageNotSeen.map((message) => message.sent_success = true);
        }
      } else {
        messages = messages.map(message => message.sent_success = true);
      }
      this.$forceUpdate();
    },
    startConversationOrSendMessage() {
      if (this.showNewChat) {
        return this.startNewConversation();
      }
      this.sendMessage();
    },
    async startNewConversation() {
      if (this.text.trim() == '') {
        return;
      }
      this.isFetchingNewChat = true;
      const message = this.text;
      this.text = '';
      this.autoResizeChatBox();
      let {data} = await conversationApi.startNewConversation({
        user_id: this.newChat.id,
        message,
      });
      this.isFetchingNewChat = false;
      if (!data.success) {
        $('#modal-notice').modal({
          keyboard: false,
          backdrop: 'static',
        });
        this.message = data.message || 'Đã có lỗi xảy ra, không thể tạo cuộc trò chuyện';
      }
      return this.$router.push({name: 'app.conversations', query: {chat: data.conversation_id}});
    },
    async sendMessage() {
      if (this.text.trim() == '') {
        return;
      }
      let messageObj = {
        conversation_id: this.currentConversation.conversation._id,
        message_body: this.text,
        is_sending: true,
        employer_send_id: this.user.id,
        created_at: new Date().getTime(),
      };
      this.currentConversation.messages.push(messageObj);
      this.mergeLastMessage(messageObj);
      const message = this.text;
      this.text = '';
      this.autoResizeChatBox();
      setTimeout(() => this.gotoBottomOfChat());
      let {data} = await conversationApi.sendMessage({
        message,
        conversation_id: this.currentConversation.conversation._id,
        employer_id: this.user.id,
        token: localStorage.getItem('employer_sso_token'),
      });
      messageObj.is_sending = false;
      messageObj.sent_success = data.success;
      messageObj._id = data.record._id;
      messageObj.created_at = data.record.created_at;
      messageObj.message_body = data.record.message_body;
      if (!data.success) {
        return alert('Đã có lỗi xảy ra không thể gửi tin nhắn');
      }
      this.conversations.map((conversation, key) => {
        if (conversation.conversation._id == this.currentConversation.conversation._id) {
          conversation.conversation.last_message = data.record.message_body;
          conversation.conversation.last_update = new Date();
          this.pushTopCoversation(key);
          return conversation;
        }
      });
    },
    async onScrollChat(el) {
      if (el.target.scrollTop == 0 && !this.isFetchedAllMessages && !this.isLoadingMoreMessage &&
          !this.isFetchingMessage && this.currentConversation.messages.length > 0) {
        this.loadingMoreMessage();
      }
    },
    async onScrollConversation(el) {
      el = el.target;
      if (el.scrollTop === (el.scrollHeight - el.offsetHeight) && !this.isFetchedAllConversations &&
          !this.isFetchingConversation && !this.isLoadingMoreConversation && this.conversations.length > 0) {
        this.isLoadingMoreConversation = true;
        let {data} = await conversationApi.fetchList({
          cursor_index: this.conversations.length,
        });
        el.scrollTo(0, el.scrollTop - 100);
        this.isLoadingMoreConversation = false;
        if (data.success) {
          if (data.conversations.length > 0) {
            for (let conversation of data.conversations) {
              this.conversations.push(conversation);
            }
            return this.$forceUpdate();
          }
          this.isFetchedAllConversations = true;
        }
      }
    },
    async loadingMoreMessage() {
      this.isLoadingMoreMessage = true;
      this.cursor_index = this.currentConversation.messages.length;
      let {data} = await conversationApi.fetchOne({
        conversation_id: this.currentConversation.conversation._id,
        cursor_index: this.cursor_index,
      });
      this.isLoadingMoreMessage = false;
      if (data.success) {
        if (data.messages.length > 0) {
          for (let message of data.messages.reverse()) {
            this.currentConversation.messages.unshift(message);
          }
          this.loadConversationMessage(this.currentConversation.messages);
          return this.mergeGroupMessagesReset();
        }
        this.isFetchedAllMessages = true;
      }
    },
    pushTopCoversation(index) {
      let conversation = this.conversations[index];
      this.conversations.unshift(JSON.parse(JSON.stringify(conversation)));
      this.conversations.splice(index + 1, 1);
      this.conversations = this.conversations.filter(conversation => conversation);
    },
    async listenSocketData() {
      this.$socket.client.on(SocketConfig.actions.RECEIVE_MESSAGE, (data) => {
        let {message, conversation, user, tab_id, notification_status} = data;
        let validConversationKey;

        if (window.setTitle) {
          clearInterval(window.setTitle);
        }
        this.conversationTitleMarked = conversation._id;

        conversation.last_message_id = message._id;
        conversation.last_message = message.message_body;
        conversation.last_update = new Date();
        let findConversation = this.conversations.find((c, key) => {
          if (c.conversation._id == conversation._id) {
            validConversationKey = key;
            return c;
          }
        });
        if (!findConversation) {
          conversation.last_message_id = message._id;
          user.conversation = conversation;
          return this.conversations.unshift(user);
        }
        findConversation.conversation.last_message_id = message._id;
        findConversation.conversation.last_message = message.message_body;
        findConversation.conversation.last_update = new Date();
        if (this.currentConversation.conversation._id == findConversation.conversation._id) {
          this.currentConversation.last_message_id = message._id;
          this.currentConversation.messages.push(message);
          this.mergeLastMessage(message);
          this.loadConversationMessage(this.currentConversation.messages);
          this.markAsSeenConversation(conversation);
          this.markAsSeenMessage(this.currentConversation.conversation._id, message._id);
          setTimeout(() => this.gotoBottomOfChat());
        }
        if (!this.focused || this.currentConversation.conversation._id != findConversation.conversation._id) {
          let showDefaultTitle = false;
          window.setTitle = setInterval(() => {
            if (showDefaultTitle) {
              showDefaultTitle = false;
              return document.title = this.defaultTitle;
            }
            document.title = `${user.fullname} đã nhắn tin cho bạn`;
            showDefaultTitle = true;
          }, 1500);
        }
        const tabId = sessionStorage.getItem('topcv_connect_tab_id');
        if (!this.focused && tabId && tabId == tab_id && notification_status) {
          this.$socket.client.emit(SocketConfig.actions.NOTIFY_WHEN_AWAY, data);
        }
        this.pushTopCoversation(validConversationKey);
        if ((conversation._id != this.currentConversation.conversation._id || !this.focused || this.isFocusedTab) &&
            this.user.sound_status) {
          document.getElementById('ting').play();
        }
      });
      this.$socket.client.on(SocketConfig.actions.MARK_AS_SEEN, (data) => {
        let {conversation_id, message_id, time} = data;
        this.currentConversation.messages.map((message) => {
          delete message.is_sending;
          delete message.sent_success;
          message.is_last_seen = false;
        });
        if (this.currentConversation.conversation._id == conversation_id) {
          this.currentConversation.conversation.last_message_id = message_id;
          this.currentConversation.conversation.last_seen_user_message.time = time;
          this.loadConversationMessage(this.currentConversation.messages);
        }
      });
      this.$socket.client.on(SocketConfig.actions.ONLINE_STATUS, (data) => {
        let {conversation_id, online, last_online} = data;
        let findConversation = this.conversations.find(
            (conversation, key) => conversation.conversation._id == conversation_id);
        if (findConversation) {
          findConversation.online = online;
          findConversation.last_online = last_online;
        }
        if (this.currentConversation.conversation._id == conversation_id) {
          this.currentConversation.user.online = online;
          this.currentConversation.user.last_online = last_online;
        }
      });
    },
    async markAsSeenMessage(conversation_id, message_id) {
      if (conversation_id && this.focused) {
        if (conversation_id == this.conversationTitleMarked) {
          clearInterval(window.setTitle);
          document.title = this.defaultTitle;
        }
        let {data} = await conversationApi.markAsSeenMessage({
          conversation_id,
          message_id,
        });
      }
    },
    openBlockCandidateModal(){
      this.showBlockCandidate = true;
      this.blockCandidateName = this.currentConversation.user.fullname;
    },
    async blockCandidate() {
      this.showBlockCandidate = false;
      console.log(this.currentConversation, this.currentCandidate)
      let {data} = await conversationApi.blockCandidate({
        conversation_id: this.currentConversation.conversation._id,
        employer_id: this.user.id,
        user_id: this.currentConversation.user.id,
        token: localStorage.getItem('employer_sso_token'),
      });
      if (data.success) {
        this.currentConversation.user.blocked = true;
        this.$forceUpdate();
      }
    },
    async unblockCandidate(){
      let {data} = await conversationApi.unblockCandidate({
        conversation_id: this.currentConversation.conversation._id,
        employer_id: this.user.id,
        user_id: this.currentConversation.user.id,
        token: localStorage.getItem('employer_sso_token'),
      });
      if (data.success) {
        this.currentConversation.user.blocked = false;
        this.$forceUpdate();
        this.$nextTick(() => {
          this.scaleStyle();
          this.gotoBottomOfChat();
          console.log("Refresh")
        });
      }
    },
    renderMessage(message) {
      /*eslint no-control-regex: "error"*/
      const urlRegex = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      /*eslint no-control-regex: "error"*/
      const domainRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      message = message.replace(/&/g, '&amp;').
          replace(/</g, '&lt;').
          replace(/>/g, '&gt;').
          replace(/"/g, '&quot;').
          replace(/'/g, '&#039;');
      if (message.match(urlRegex)) {
        return message.replace(urlRegex, (url) => {
          return `<a class="text-link" target="_blank" href="${url}" rel="noopener noreferrer">${url}</a>`;
        });
      }
      if (message.match(domainRegex)) {
        return message.replace(domainRegex, (domain) => {
          return `<a class="text-link" target="_blank" href="${domain}" rel="noopener noreferrer">${domain}</a>`;
        });
      }
      return message;
    },
    mergeGroupMessagesReset() {
      this.groupMessages = [];
      this.mergeGroupMessages();
    },
    mergeLastMessage(message) {
      let {employer_send_id, created_at} = message;
      let groupMessages = this.groupMessages;
      const validGroupMessage = groupMessages.length > 0 && groupMessages[groupMessages.length - 1].length > 0;
      if (validGroupMessage) {
        let messagesInGroup = groupMessages[groupMessages.length - 1];
        let messageGroup = messagesInGroup[messagesInGroup.length - 1];
        let nearMessageTime = this.dateDiffInMinutes(new Date(created_at), new Date(messageGroup.created_at)) <=
            this.splitGroupMessageTime;
        if (messageGroup.employer_send_id == employer_send_id && nearMessageTime) {
          return groupMessages[groupMessages.length - 1].push(message);
        }
      }
      if (!groupMessages[groupMessages.length]) {
        groupMessages[groupMessages.length] = [message];
      }
    },
    mergeGroupMessages(msgIndex = 0, groupIndex = 0) {
      const messages = this.currentConversation.messages;
      if (messages.length > 0) {
        if (!messages[msgIndex + 1]) {
          this.mergeLastMessage(messages[msgIndex]);
          return;
        }
        if (!this.groupMessages[groupIndex]) {
          this.groupMessages[groupIndex] = [];
        }
        this.groupMessages[groupIndex].push(messages[msgIndex]);
        let sameIdConditional = messages[msgIndex].employer_send_id == messages[msgIndex + 1].employer_send_id;
        let nearMessageTime = this.dateDiffInMinutes(new Date(messages[msgIndex + 1].created_at),
            new Date(messages[msgIndex].created_at)) <= this.splitGroupMessageTime;
        if (messages[msgIndex].message_type === 4 && !!messages[msgIndex].user_send_id) {
          return this.mergeGroupMessages(msgIndex + 1, groupIndex + 1);
        }
        if (sameIdConditional && nearMessageTime) {
          return this.mergeGroupMessages(msgIndex + 1, groupIndex);
        }
        return this.mergeGroupMessages(msgIndex + 1, groupIndex + 1);
      }
    },
    dateDiffInMinutes(dateBefore, dateAfter) {
      let diff = Math.abs(new Date(dateAfter) - new Date(dateBefore));
      return Math.floor((diff / 1000) / 60);
    },
    dateFormat(date) {
      return moment(date).format('H:m DD/MM/YYYY');
    },
    showChatTimeWhenFarGroup(index) {
      let groupMessages = this.groupMessages;
      let currentGroupMessages = groupMessages[index];
      let prevGroupMessage = groupMessages[parseInt(index) - 1];
      if (!prevGroupMessage || prevGroupMessage.length == 0) {
        return false;
      }
      return this.dateDiffInMinutes(new Date(currentGroupMessages[currentGroupMessages.length - 1].created_at),
          new Date(prevGroupMessage[0].created_at)) > this.splitGroupMessageTime;
    },
    isSeenLastMessage(conversation) {
      let {last_seen_employer_message, last_message_id} = conversation;
      return last_seen_employer_message && last_seen_employer_message._id && last_seen_employer_message._id ==
          last_message_id;
    },
    timeAgo(time) {
      return moment(time).locale('vi').fromNow();
    },
    changeBrowserQueriesUrl(url, params = null) {
      let queryParams = new URLSearchParams(window.location.search);
      if (params) {
        for (let param of Object.keys(params)) {
          queryParams.set(param, params[param]);
        }
        url += `/?${queryParams.toString()}`;
      }
      history.replaceState(null, null, `/${url}`);
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (event.target.value.trim() == '') {
          return this.conversationsSearched = [];
        }
        this.isSearchingConversation = true;
        let {data} = await conversationApi.fetchList({
          cursor_index: 0,
          keyword: event.target.value,
        });
        this.isSearchingConversation = false;
        if (!data.success) {
          return;
        }
        this.conversationsSearched = data.conversations;
      }, 600);
    },
    onBlurOfSearchConversation(e) {
      e.target.value = '';
      setTimeout(() => {
        this.isFocusedSearchInput = false;
      }, 100);
    },
    showMoreAppliedJobByUser() {
      this.showAllAppliedJobByUser = !this.showAllAppliedJobByUser;
      setTimeout(() => {
        this.scaleStyle();
      });
    },
    showMoreSuperFastJobByUser() {
      this.showAllSuperFastJobByUser = !this.showAllSuperFastJobByUser;
      setTimeout(() => {
        this.scaleStyle();
      });
    },
    async toggleNotificationOrSoundStatus(properties) {
      let {data} = await authApi.toggleNotificationOrSoundStatus(properties);
      if (data.success) {
        this.$store.dispatch('auth/setProperties', data);
      }
    },
    isLongMessage(message) {
      console.log({a: message.split("\n").length})
      return message.split("\n").length > 3
    },
    handleExpandMessage(id) {
      if (this.expandMessage?.[id]) {
        this.expandMessage[id] = !this.expandMessage[id]
      } else {
        this.expandMessage = {...this.expandMessage, [id]: true}
      }
    },
    goViewCV(userId) {
      window.open(process.env.VUE_APP_EMPLOYER_V2_URL + '/cvs-management/profile-pro/' + userId, '_blank');
    },
  },
};
</script>
