import axios from "axios";

export default {
    getLatestAppliedJob() {
        return axios.get("applied/get-latest-apply");
    },
    getAppliedJobByUser({user_id}) {
        return axios.get(`/${user_id}/applied-jobs`);
    },
    getFastJobByUser({user_id}) {
        return axios.get(`/${user_id}/fast-jobs`);
    }
}