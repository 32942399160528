<template>
  <div class="modal" id="modal-block-candidate" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">Chặn tin nhắn</h5>
        </div>
        <div class="modal-body">
          <div class="list-item">
            <div class="list-item-content">
              <p>Bạn có chắc chắn muốn chặn ứng viên <strong>{{candidateName}}</strong> không?</p>
              <ul style="padding: 0 0 0 20px;">
                <li>Bạn sẽ không nhận được tin nhắn từ tài khoản ứng viên <strong>{{candidateName}}</strong></li>
                <li>Bạn vẫn có thể xem và thao tác với các CV của ứng viên <strong>{{candidateName}}</strong></li>
              </ul>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <div class="text-right">
            <button type="button" @click="closeModal()" class="btn btn-light mr-2">Đóng</button>
            <button type="button" @click="blockCandidate()" class="btn btn btn-danger">Xác nhận</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import authApi from "@/api/authApi";

export default {
  props: ['onSubmit', "onClose", 'showCandidate', 'candidateName'],
  data() {
    return {
    }
  },
  watch: {
    showCandidate: function (val) {
      if (val) {
        $("#modal-block-candidate").modal({
          keyboard: false,
          backdrop: 'static',
        });
      }else{
        $("#modal-block-candidate").modal('hide');
      }
    }
  },
  methods: {
    async blockCandidate() {
      $("#modal-block-candidate").modal('hide');
      this.onSubmit && this.onSubmit();
      this.$emit('onSubmitModal');
    },
    closeModal() {
      $("#modal-block-candidate").modal('hide');
      this.$emit('onCloseModal');
      setTimeout(() => {
        this.onClose && this.onClose();
      }, 200);
    }
  }
}
</script>

<style scoped>
.list-item {
  display: flex;
}

.list-item-number {
  min-width: 30px;
  margin-right: 16px;
}

.list-item-number-wrapper {
  width: 25px;
  height: 25px;
  background: #E7E9EA;
  text-align: center;
  border-radius: 50%;
  color: #212F3F;
}

.list-item {
  margin-bottom: 10px;
}

.list-item-number {
  margin-top: 5px;
}

.list-item {
  margin: 0;
}

.list-item-content {
  padding: 20px;
}

.modal-header {
  padding: 0;
}

.modal-content {
  margin-top: calc(50vh - 100px);
  max-width: 600px
}

.modal-body {
  padding: 0;
}

.list-item-content p {
  margin: 0;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
}

h5.modal-title {
  width: 100%;
  color: #303235;
  font-weight: 600;
  text-align: left;
  font-size: 16px;
  padding: 20px
}
.modal-footer btn{
  font-weight: bold;
}
.modal.show .modal-dialog{
  max-width: 600px !important;
}
</style>